// Types
import { Buttons, MainIconLandingProps } from './MainIconLanding.types'

// Icons
import Implantable from 'assets/icons/svgs/Implantable.svg'
import PreferenceCardIcon from 'assets/icons/svgs/prefCardInactive_new.svg'
import ConsumableProducts from 'assets/icons/svgs/ConsumableProducts.svg'
import BiologicIcon from 'assets/icons/svgs/ImplantableBiological.svg'
import SalesRepIcon from 'assets/icons/svgs/SalesRepIcon.svg'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import ImplantableOtherIcon from 'assets/icons/svgs/ImplantableOther.svg'
import ExplantedIcon from 'assets/icons/images/Explanted.png'

// Other
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { useUser } from 'app/User'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { useEffect, useState } from 'react'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'
import {
  useBatchAddAssetsToSurgery,
  useGetPatientOldImplants,
  useUpdateSurgeryAutopopulateMutation,
} from 'lib/apollo/hooks'
import { MediaAction, Scan, SelectedScan } from 'common/types'

export const useMainIconLandingLogic = ({ surgery }: MainIconLandingProps) => {
  const { user } = useUser()
  const flags = useFlags()
  const isMobile = useIsMobile()
  const { isRep: isRepUser } = useUser()
  const { handleChangeAssetType } = useAssetType()
  const { enableConsumablesAutopopulate } = useOrganizationSettings()

  const [twoColumnsClass, setTwoColumnsClass] = useState<string>('')
  const [isDispositionModalOpen, setIsDispositionModalOpen] = useState(false)
  const [uploadedMedia, setUploadedMedia] = useState<string[]>([])
  const [selectedOldImplants, setSelectedOldImplants] = useState<
    SelectedScan[]
  >([])
  const [selectOldExplantsModalOpen, setSelectOldExplantsModalOpen] =
    useState<boolean>(false)
  const [showConsumablesAutopopulate, setShowConsumablesAutopopulate] =
    useState(false)

  const [updateSurgeryAutopopulateSelection, { loading }] =
    useUpdateSurgeryAutopopulateMutation()

  const [
    batchAddAssetsToSurgery,
    { loading: loadingBatchAddAssets, data: batchAddAssetsToSurgeryData },
  ] = useBatchAddAssetsToSurgery(surgery._id)

  const [
    getPatientOldImplants,
    { data: oldImplantsData, loading: loadingOldImplants },
  ] = useGetPatientOldImplants({
    patient: surgery.patient._id,
    surgery: surgery._id,
  })

  const [oldImplants, setOldImplants] = useState<Scan[]>([])

  const repButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
  }

  const nurseButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
    biologic: {
      IconComponent: BiologicIcon,
      label: 'Implantable Biologic',
      dataTestId: 'implantable-biologic-button',
    },
    consumables: {
      IconComponent: ConsumableProducts,
      label: 'Consumable Product',
      dataTestId: 'consumable-product-button',
    },
    trays: {
      IconComponent: TrayIcon,
      label: 'Trays',
      dataTestId: 'tray-button',
      isVisible: flags.assetTypeTraysButton,
    },
    preferenceCard: {
      IconComponent: PreferenceCardIcon,
      label: 'Preference Card',
      dataTestId: 'preference-card-button',
      isVisible: flags.assetTypePreferenceCardButton,
    },
    salesRep: {
      IconComponent: SalesRepIcon,
      label: 'Add Sales Rep',
      dataTestId: 'add-sales-rep-button',
      isVisible: flags.assetTypeAddSalesRepButton,
    },
    explanted: {
      IconComponent: ExplantedIcon,
      label: 'Explant',
      dataTestId: 'explanted-button',
    },
  }

  const icons = isRepUser ? repButtons : nurseButtons

  const visibleIconsNumber =
    Object.keys(icons).length -
    Object.values(icons).filter((icon) => icon.isVisible === false).length

  useEffect(() => {
    switch (visibleIconsNumber) {
      case 4:
        setTwoColumnsClass('two-columns')
        break
      default:
        setTwoColumnsClass('')
        break
    }
  }, [visibleIconsNumber])

  useEffect(() => {
    if (
      enableConsumablesAutopopulate &&
      surgery.hasPreferenceCardConsumables &&
      !surgery.isAutopopulateConsumableOptionSelected
    ) {
      setShowConsumablesAutopopulate(true)
    }
  }, [
    enableConsumablesAutopopulate,
    surgery.hasPreferenceCardConsumables,
    surgery.isAutopopulateConsumableOptionSelected,
  ])

  const populateConsumables = (autoPopulateConsumables: boolean) => {
    updateSurgeryAutopopulateSelection({
      variables: {
        input: {
          surgeryId: surgery._id,
          autoPopulateConsumables,
        },
      },
    }).then((response) => {
      if (response?.data?.updateSurgeryAutopopulate.success) {
        setShowConsumablesAutopopulate(false)
      }
    })
  }

  const handleExplants = () => {
    getPatientOldImplants().then(({ data }) => {
      const implants = data?.getPatientOldImplants.implants
      if (implants && implants.length > 0) {
        setSelectOldExplantsModalOpen(true)
      } else {
        handleChangeAssetType('EXPLANTED')
      }
    })
  }

  const handleAutoExplantSave = (args: {
    selectedScans: SelectedScan[]
    explantedReason: string
    explantedReasonNote?: string | null
  }) => {
    batchAddAssetsToSurgery({
      variables: {
        surgeryId: surgery._id,
        dispositions: args.selectedScans.map((scan) => ({
          assetType: scan.assetType,
          implantStatus: 'EXPLANTED',
          originalImplantAssetId: scan._id,
          implantSite: scan.implantSite,
          siteLocation: scan.siteLocation,
          explantedReason: args.explantedReason,
          explantedReasonNote: args.explantedReasonNote,
        })),
        scans: args.selectedScans.map((scan) => ({
          media: uploadedMedia,
          scanId: scan._id,
          userId: user?.id || '',
          bidAssetId: scan.bidAssetId,
          bidAssetInstanceId: scan.bidAssetInstanceId,
          bidCompanyId: scan.bidCompanyId,
          secondaryDeviceIdType: scan.secondaryDeviceIdType,
          count: scan.count,
          deviceCount: scan.deviceCount,
          companyName: scan.companyName,
          deviceId: scan.deviceId,
          lotBatch: scan.lotBatch,
          udi: scan.udi,
          deviceDescription: scan.deviceDescription,
          catalogNumber: scan.catalogNumber,
          versionModelNumber: scan.versionModelNumber,
          serialNumber: scan.serialNumber,
          sizeText: scan.sizeText,
          sizeString: scan.sizeString,
          expirationDate: scan.expirationDate,
          manufacturingDate: scan.manufacturingDate,
          cost: scan.cost,
          chargeable: scan.chargeable,
          isManualAddition: scan.isManualAddition,
          isDTMScrew: scan.isDTMScrew,
          isRepAddition: scan.isRepAddition,
          isMultiPack: false,
          parentCompany: scan.parentCompany,
          parentCompanyId: scan.parentCompanyId,
          isSponge: false,
          originalImplantAssetId: scan._id,
          // isRepAddition: scan.isRepAddition,
        })),
      },
    })
  }

  const handleSaveUploadedMedia = (media: string[], action: MediaAction) => {
    setUploadedMedia((prev) => {
      if (action === 'add') {
        return [...prev, ...media]
      } else if (action === 'delete') {
        return prev.filter((item) => !media.includes(item))
      }
      return prev
    })
  }

  useEffect(() => {
    if (selectOldExplantsModalOpen) {
      setOldImplants(
        oldImplantsData?.getPatientOldImplants?.implants?.map(
          ({ __typename, ...item }) => ({
            ...item,
            isSelected: false,
          })
        ) || []
      )
    } else {
      setOldImplants([])
    }
  }, [oldImplantsData, selectOldExplantsModalOpen])

  useEffect(() => {
    if (
      batchAddAssetsToSurgeryData?.batchAddAssetsToSurgery?.addedScanIds?.[0]
    ) {
      setSelectOldExplantsModalOpen(false)
      setIsDispositionModalOpen(false)
      setOldImplants([])
      setUploadedMedia([])
      return
    }
  }, [batchAddAssetsToSurgeryData, loadingBatchAddAssets])

  return {
    icons,
    loading,
    isMobile,
    oldImplants,
    twoColumnsClass,
    loadingOldImplants,
    selectedOldImplants,
    loadingBatchAddAssets,
    isDispositionModalOpen,
    selectOldExplantsModalOpen,
    showConsumablesAutopopulate,
    handleExplants,
    populateConsumables,
    handleChangeAssetType,
    handleAutoExplantSave,
    setSelectedOldImplants,
    handleSaveUploadedMedia,
    setIsDispositionModalOpen,
    setSelectOldExplantsModalOpen,
  }
}

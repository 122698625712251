/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import useZimmerTrayMapLogic from 'lib/hooks/useZimmerTrayMapLogic/useZimmerTrayMapLogic'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import {
  GrayScaleTrayMapProps,
  ScrewAnnotation,
  PlateAnnotation,
  DrillAnnotation,
  TrayData,
  Point,
} from './GrayScale.types'
import useTrayMapLogic from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { rodUDIMap } from '../ZimmerRodTrayMap/ZimmerRodTrayMap.types'
// Stryker Mid Face
import midFaceImage from './IMG_2800_rectified.png'
import midFaceData from './IMG_2800_results.transformed.json'

// Stryker Upper Face
import upperFaceImage from './IMG_2766_rectified.png'
import upperFaceData from './IMG_2766.transformed.json'

// Zimmer Curved Rods
import zimmerCurvedImage from './IMG_3630_rectified.png'
import zimmerCurvedData from './IMG_3630_results.transformed.json'

// Stryker Screw Caddy
import screwCaddyImage from './screw.png'
import screwCaddyData from './screw.transformed.json'

// Zimmer Adjustable Transverse Connector
import zimmerAdjustableImage from './IMG_0945_rectified.png'
import zimmerAdjustableData from './IMG_0945.transformed.json'

import { useAnnotations } from './hooks/useAnnotations'
import { useScaling } from './hooks/useScaling'
import { DEFAULT_DISPLAY_WIDTH } from './constants'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { useAssignedDigitalTrays } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'

export const useGrayScaleLogic = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
  trayType,
  setSelectedScrew,
  mapContent,
  isSPD = false,
}: GrayScaleTrayMapProps = {}) => {
  const [pendingScrewAsset, setPendingScrewAsset] = useState<any>(null)
  const [universalJsonData, setUniversalJsonData] = useState<TrayData | null>(
    null
  )
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>(
    undefined
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const [endPoint, setEndPoint] = useState<Point | null>(null)
  const [isMultiSelectConfirmOpen, setIsMultiSelectConfirmOpen] =
    useState(false)

  const {
    trayPlates,
    trayScrews,
    setTrayPlates,
    setTrayScrews,
    setScrewLoadModalOpen,
    screwLoadModalOpen,
    selectedScrews,
    setSelectedScrews,
    selectedScrewIndices,
    setSelectedScrewIndices,
    parLevel,
  } = useSPD()
  const { usageScrews, setUsageScrews } = useAssignedDigitalTrays()
  const { isLoadMultipleBoxVisible } = useSPDScan()

  const {
    isLoadingWithUDIs,
    setIsLoadingWithUDIs,
    handleCancelSelection,
    handleLoadingWithUDIs,
  } = useTrayMapLogic()

  const {
    screwAnnotations,
    plateAnnotations,
    drillAnnotations,
    selectedAnnotation,
    setScrewAnnotations,
    setSelectedAnnotation,
    initializeAnnotations,
  } = useAnnotations()

  const {
    originalWidth,
    originalHeight,
    imageScale,
    uiScale,
    setImageScale,
    setUiScale,
    setOriginalWidth,
    setOriginalHeight,
    updateScales,
    textScale,
    calculateDynamicScale,
  } = useScaling()

  const {
    handlePlateCountChange,
    shouldPlateIncreaseBeDisabled,
    setInitialTrayPlates,
    getPlateDI,
  } = useZimmerTrayMapLogic({
    isInOR,
    setSelectedPlate,
    setModalOpen,
    udiMap: rodUDIMap,
    parLevel,
  })

  useEffect(() => {
    if (!trayType) return

    const trayConfig = {
      'stryker upper face': { image: upperFaceImage, data: upperFaceData },
      'stryker middle face': { image: midFaceImage, data: midFaceData },
      'zimmer curved rods': {
        image: zimmerCurvedImage,
        data: zimmerCurvedData,
      },
      'stryker screw caddy': { image: screwCaddyImage, data: screwCaddyData },
      'zimmer adjustable transverse connector': {
        image: zimmerAdjustableImage,
        data: zimmerAdjustableData,
      },
    }

    const config =
      trayConfig[trayType.toLowerCase() as keyof typeof trayConfig] ||
      trayConfig['stryker upper face']
    setBackgroundImage(config.image)
    setUniversalJsonData(config.data as unknown as TrayData)
  }, [trayType])

  useEffect(() => {
    if (!containerRef.current || !originalWidth) return

    const updateScales = () => {
      const containerWidth = containerRef.current?.clientWidth || 0

      // Scale for image and coordinates (based on original dimensions)
      const newImageScale = containerWidth / originalWidth
      setImageScale(newImageScale)

      // Scale for UI elements (based on default display width)
      const newUiScale = containerWidth / DEFAULT_DISPLAY_WIDTH
      setUiScale(newUiScale)
    }

    updateScales()
    const resizeObserver = new ResizeObserver(updateScales)
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [originalWidth, containerRef.current])

  useEffect(() => {
    if (mapContent) {
      if (!trayScrews?.length) {
        setTrayScrews(mapContent.screws || [])
      }
      if (!trayPlates?.length) {
        setTrayPlates(mapContent.plates || [])
      }
    }
  }, [mapContent])

  useEffect(() => {
    if (universalJsonData) {
      const trayData = universalJsonData as unknown as {
        [key: string]: {
          screw?: ScrewAnnotation[]
          plate?: PlateAnnotation[]
          drill?: DrillAnnotation[]
          dimensions?: { width: number; height: number }
        }
      }
      const firstKey = Object.keys(trayData)[0]
      const dimensions = firstKey ? trayData[firstKey].dimensions : null

      if (dimensions) {
        setOriginalWidth(dimensions.width)
        setOriginalHeight(dimensions.height)
      }

      initializeAnnotations(trayData, trayScrews, usageScrews)
    }
  }, [universalJsonData, trayScrews, usageScrews])

  const handleScrewRemoval = (
    screwAsset: BetterIDTrayScrew,
    annotation: ScrewAnnotation,
    index: number
  ) => {
    if (setSelectedScrew) {
      setSelectedScrew(screwAsset)
    }

    if (setModalOpen) {
      setModalOpen(true)
    }

    setTrayScrews((prev) =>
      prev.filter(
        (ts) =>
          !(
            ts.row === annotation.row_label &&
            ts.column === annotation.column_label &&
            ts.x === annotation.column_index &&
            ts.label === annotation.label
          )
      )
    )

    setScrewAnnotations((prev) =>
      prev.map((screw, i) => (i === index ? { ...screw, count: 0 } : screw))
    )
  }

  const handleScrewAddition = (
    index: number,
    annotation: ScrewAnnotation,
    screwAsset: BetterIDTrayScrew,
    exists: boolean
  ) => {
    if (!exists) {
      setPendingScrewAsset(screwAsset)
      if (isSPD) {
        setScrewLoadModalOpen(true)
        setSelectedScrews([screwAsset])
      } else {
        setIsLoadingWithUDIs(false)
        setScrewAnnotations((prev) =>
          prev.map((screw, i) => (i === index ? { ...screw, count: 1 } : screw))
        )
        setTrayScrews((prev) => [...prev, screwAsset])
        setUsageScrews((prev) =>
          prev.filter(
            (screw) =>
              !(
                screw.label === screwAsset.label &&
                screw.row === screwAsset.row &&
                screw.x === screwAsset.x
              )
          )
        )
      }
    } else {
      setTrayScrews((prev) =>
        prev.filter(
          (ts) =>
            !(
              ts.row === annotation.row_label &&
              ts.column === annotation.column_label &&
              ts.x === annotation.column_index &&
              ts.label === annotation.label
            )
        )
      )

      setScrewAnnotations((prev) =>
        prev.map((screw, i) => (i === index ? { ...screw, count: 0 } : screw))
      )
    }
  }
  const handleScrewClick = (index: number) => {
    const annotation = screwAnnotations[index]
    const screwAsset = {
      label: annotation.label,
      row: annotation.row_label,
      column: annotation.column_label,
      x: annotation.column_index,
    }

    const exists = trayScrews.some(
      (ts) =>
        ts.row === annotation.row_label &&
        ts.column === annotation.column_label &&
        ts.x === annotation.column_index &&
        ts.label === annotation.label
    )

    if (isLoadMultipleBoxVisible) {
      handleLoadingWithUDIs(screwAsset)
      return
    }

    if (annotation.count === 1) {
      if (isInOR) {
        if (selectedScrewIndices.includes(index)) {
          setSelectedScrewIndices((prev) => prev.filter((i) => i !== index))
        } else {
          setSelectedScrewIndices((prev) => [...prev, index])
        }
        return
      }
      handleScrewRemoval(screwAsset, annotation, index)
    } else {
      handleScrewAddition(index, annotation, screwAsset, exists)
    }
  }

  const handleSPDCountIncrease = (type: 'plate' | 'drill', index: number) => {
    const annotation =
      type === 'plate' ? plateAnnotations[index] : drillAnnotations[index]

    const asset = {
      deviceId: getPlateDI(annotation.label),
      plateName: annotation.label,
      plateCount: annotation.count,
      type: type,
    }

    setIsLoadingWithUDIs(true)
    setScrewLoadModalOpen(true)
    setPendingScrewAsset(asset)
    return
  }

  const handlePlateCountModification = (
    type: 'plate' | 'drill',
    index: number,
    change: number
  ) => {
    const plate = plateAnnotations[index]
    const plateName = plate.label
    const currentPlate = trayPlates.find((p) => p.plateName === plateName)

    // If decreasing count in OR mode
    if (change < 0 && isInOR && currentPlate) {
      if (setSelectedPlate) {
        setSelectedPlate(currentPlate)
      }
      if (setModalOpen) {
        setModalOpen(true)
      }
      return
    }

    // Normal count change (not decreasing in OR)
    const currentCount = currentPlate?.plateCount || 0
    const newCount = Math.max(0, currentCount + change)
    handlePlateCountChange(plateName as any, newCount)
  }

  const handleDrillCountModification = (index: number, change: number) => {
    const drill = drillAnnotations[index]
    const drillName = drill.label
    const currentDrill = trayPlates.find((p) => p.plateName === drillName)

    // If decreasing count in OR mode
    if (change < 0 && isInOR && currentDrill) {
      if (setSelectedPlate) {
        setSelectedPlate(currentDrill)
      }
      if (setModalOpen) {
        setModalOpen(true)
      }
      return
    }

    // Normal count change (not decreasing in OR)
    const currentCount = currentDrill?.plateCount || 0
    const newCount = Math.max(0, currentCount + change)
    handlePlateCountChange(drillName as any, newCount)
  }

  const handleCountChange = (
    type: 'plate' | 'drill',
    index: number,
    change: number
  ) => {
    if (change > 0 && isSPD) {
      handleSPDCountIncrease(type, index)
      return
    }

    if (type === 'plate') {
      handlePlateCountModification(type, index, change)
    } else {
      handleDrillCountModification(index, change)
    }
  }

  const handleCountCircleClick = (type: 'plate' | 'drill', index: number) => {
    setSelectedAnnotation(`${type}-${index}`)
  }

  const isInSelectionBounds = (
    screwCenter: number[],
    selectionBox: { left: number; top: number; right: number; bottom: number }
  ) => {
    const [x, y] = screwCenter
    const { left, top, right, bottom } = selectionBox
    return x >= left && x <= right && y >= top && y <= bottom
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isLoadingWithUDIs) return

    const rect = containerRef.current?.getBoundingClientRect()
    if (!rect) return

    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    setIsDragging(true)
    setStartPoint({ x, y })
    setEndPoint({ x, y })
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !containerRef.current) return

    const rect = containerRef.current.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    setEndPoint({ x, y })
  }

  const handleMouseUp = () => {
    if (!isDragging || !startPoint || !endPoint) return

    const selectionBox = {
      left: Math.min(startPoint.x, endPoint.x),
      top: Math.min(startPoint.y, endPoint.y),
      right: Math.max(startPoint.x, endPoint.x),
      bottom: Math.max(startPoint.y, endPoint.y),
    }

    const selectedIndices = screwAnnotations
      .map((annotation, index) => {
        if (!annotation.img_screw_center) return null
        const [cx, cy] = annotation.img_screw_center
        const scaledX = cx * imageScale
        const scaledY = cy * imageScale

        // Only include screws that are not loaded (count === 0)
        if (annotation.count > 0) return null

        return isInSelectionBounds([scaledX, scaledY], selectionBox)
          ? index
          : null
      })
      .filter((index): index is number => index !== null)

    if (selectedIndices.length > 0) {
      setSelectedScrewIndices(selectedIndices)
      setIsMultiSelectConfirmOpen(true)
    }

    setIsDragging(false)
    setStartPoint(null)
    setEndPoint(null)
  }

  const handleMultiSelectConfirm = () => {
    selectedScrewIndices.forEach((index) => {
      const annotation = screwAnnotations[index]
      const screwAsset = {
        label: annotation.label,
        row: annotation.row_label,
        column: annotation.column_label,
        x: annotation.column_index,
      }

      const exists = trayScrews.some(
        (ts) =>
          ts.row === annotation.row_label &&
          ts.column === annotation.column_label &&
          ts.x === annotation.column_index &&
          ts.label === annotation.label
      )

      if (!exists) {
        setTrayScrews((prev) => [...prev, screwAsset])
        setScrewAnnotations((prev) =>
          prev.map((screw, i) => (i === index ? { ...screw, count: 1 } : screw))
        )
      }
    })

    setIsMultiSelectConfirmOpen(false)
    setSelectedScrewIndices([])
  }

  const handleMultiSelectCancel = () => {
    setIsMultiSelectConfirmOpen(false)
    setSelectedScrewIndices([])
  }

  const getSelectedTrayScrews = (selectedIndices: number[]) => {
    return selectedIndices
      .map((index) => {
        const annotation = screwAnnotations[index]

        if (!annotation) return undefined

        return trayScrews.find(
          (ts) =>
            ts.row === annotation.row_label &&
            ts.column === annotation.column_label &&
            ts.x === annotation.column_index &&
            ts.label === annotation.label
        )
      })
      .filter(
        (screw): screw is NonNullable<typeof screw> => screw !== undefined
      )
  }
  const loadAllScrews = () => {
    if (isLoadingWithUDIs) return

    const newScrews = screwAnnotations
      .map((annotation) => {
        if (annotation.count === 0) {
          return {
            label: annotation.label,
            row: annotation.row_label,
            column: annotation.column_label,
            x: annotation.column_index,
          }
        }
        return null
      })
      .filter((screw): screw is BetterIDTrayScrew => screw !== null)

    setTrayScrews((prev) => [...prev, ...newScrews])
    setScrewAnnotations((prev) => prev.map((screw) => ({ ...screw, count: 1 })))
  }

  const removeAllScrews = () => {
    setTrayScrews([])
    setScrewAnnotations([])
  }

  const increaseAllPlatesBy = (amount: number) => {
    if (amount <= 0) return

    const updatedPlates = [...trayPlates]
    const allAnnotations = [...plateAnnotations, ...drillAnnotations]
    const updatedAnnotations = [...plateAnnotations, ...drillAnnotations]

    allAnnotations.forEach((annotation, index) => {
      const plateName = annotation.label
      const currentPlate = trayPlates.find((p) => p.plateName === plateName)
      const currentCount = currentPlate?.plateCount || 0
      const newCount = Math.min(currentCount + amount, parLevel)

      if (currentPlate) {
        const plateIndex = updatedPlates.findIndex(
          (p) => p.plateName === plateName
        )
        if (plateIndex !== -1) {
          updatedPlates[plateIndex] = {
            ...updatedPlates[plateIndex],
            plateCount: newCount,
          }
        }
      } else if (newCount > 0) {
        updatedPlates.push({
          deviceId: getPlateDI(plateName),
          plateName: plateName,
          plateCount: newCount,
        })
      }

      updatedAnnotations[index] = {
        ...updatedAnnotations[index],
        count: newCount,
      }
    })

    if (!isSPD) {
      setTrayPlates(updatedPlates)
    }
  }

  const decreaseAllPlatesBy = (amount: number) => {
    if (amount <= 0) return

    const updatedPlates = [...trayPlates]
    const allAnnotations = [...plateAnnotations, ...drillAnnotations]
    const updatedAnnotations = [...plateAnnotations, ...drillAnnotations]

    allAnnotations.forEach((annotation, index) => {
      const plateName = annotation.label
      const currentPlate = trayPlates.find((p) => p.plateName === plateName)
      const currentCount = currentPlate?.plateCount || 0
      const newCount = Math.max(currentCount - amount, 0)

      if (isInOR) return

      const plateIndex = updatedPlates.findIndex(
        (p) => p.plateName === plateName
      )
      if (plateIndex !== -1) {
        if (newCount === 0) {
          updatedPlates.splice(plateIndex, 1)
        } else {
          updatedPlates[plateIndex] = {
            ...updatedPlates[plateIndex],
            plateCount: newCount,
          }
        }
      }

      updatedAnnotations[index] = {
        ...updatedAnnotations[index],
        count: newCount,
      }
    })

    if (!isInOR) {
      setTrayPlates(updatedPlates)
    }
  }

  const isAllScrewsLoaded = screwAnnotations.every((screw) => screw.count > 0)
  const isAllPlatesLoaded = trayPlates.every((plate) => plate.plateCount > 0)

  return {
    containerRef,
    backgroundImage,
    pendingScrewAsset,
    screwLoadModalOpen,
    trayScrews,
    trayPlates,
    screwAnnotations,
    plateAnnotations,
    drillAnnotations,
    selectedAnnotation,
    selectedScrews,
    setSelectedAnnotation,
    initializeAnnotations,
    originalWidth,
    originalHeight,
    imageScale,
    uiScale,
    textScale,
    calculateDynamicScale,
    updateScales,
    shouldPlateIncreaseBeDisabled,
    setInitialTrayPlates,
    getPlateDI,
    handleScrewClick,
    handleCountChange,
    handleCountCircleClick,
    handleCancelSelection,
    isDragging,
    startPoint,
    endPoint,
    selectedScrewIndices,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isMultiSelectConfirmOpen,
    handleMultiSelectConfirm,
    handleMultiSelectCancel,
    isLoadingWithUDIs,
    usageScrews,
    getSelectedTrayScrews,
    loadAllScrews,
    increaseAllPlatesBy,
    decreaseAllPlatesBy,
    removeAllScrews,
    isAllScrewsLoaded,
    isAllPlatesLoaded,
  }
}

export default useGrayScaleLogic

// Components
import { CircularProgress, Typography } from '@mui/material'
import LandingIcon from './LandingIcon/LandingIcon'

// Types
import { MainIconFlowState } from 'common/types'

// Logic
import { useMainIconLandingLogic } from './MainIconLanding.logic'

// Styles
import classNames from 'classnames'
import './MainIconLanding.scss'

// Other
import { Helmet } from 'react-helmet'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { MainIconLandingProps } from './MainIconLanding.types'
import { FC } from 'react'
import { SelectOldExplantsModal } from './SelectOldExplantsModal/SelectOldExplantsModal'
import { BatchDispositionModal } from 'components/BatchDispositionModal/BatchDispositionModal'

const MainIconLanding: FC<MainIconLandingProps> = ({ surgery }) => {
  const {
    icons,
    loading,
    isMobile,
    oldImplants,
    twoColumnsClass,
    loadingOldImplants,
    selectedOldImplants,
    loadingBatchAddAssets,
    isDispositionModalOpen,
    selectOldExplantsModalOpen,
    showConsumablesAutopopulate,
    handleExplants,
    populateConsumables,
    handleChangeAssetType,
    handleAutoExplantSave,
    setSelectedOldImplants,
    handleSaveUploadedMedia,
    setIsDispositionModalOpen,
    setSelectOldExplantsModalOpen,
  } = useMainIconLandingLogic({ surgery })

  return (
    <div
      className={classNames('main-icon-landing_wrapper', {
        'is-mobile': isMobile,
      })}
    >
      {loadingOldImplants && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}

      <Helmet>
        <title>Home</title>
      </Helmet>

      <Typography variant="h1" align="center">
        Select Product Type
      </Typography>

      <div className={`icons-container ${twoColumnsClass}`}>
        {Object.entries(icons).map(
          ([key, { IconComponent, label, dataTestId, isVisible }]) => {
            return isVisible === false ? null : (
              <LandingIcon
                key={key}
                isMobile={isMobile}
                onClick={() =>
                  label === 'Explant'
                    ? handleExplants()
                    : handleChangeAssetType(
                        label?.toUpperCase() as MainIconFlowState['assetTypeLabel']
                      )
                }
                icon={IconComponent}
                label={label}
                dataTestId={dataTestId}
              />
            )
          }
        )}
      </div>

      <CustomModal
        header="Consumables Autopopulate"
        fitContentWidth={!isMobile}
        open={showConsumablesAutopopulate}
        isLoadingState={loading}
        primaryButtonText="YES"
        secondaryButtonText="NO"
        onCloseTakeSecondaryButtonAction={false}
        secondaryButtonAction={() => populateConsumables(false)}
        handleOnSubmit={() => populateConsumables(true)}
      >
        <Typography>
          A preference card was found for this surgery, Would you like to add
          all the consumables from it?
        </Typography>
      </CustomModal>

      {selectOldExplantsModalOpen && <SelectOldExplantsModal
        isLoading={loadingOldImplants}
        oldImplants={oldImplants}
        open={selectOldExplantsModalOpen}
        onClose={() => setSelectOldExplantsModalOpen(false)}
        onSetIsDispositionModalOpen={setIsDispositionModalOpen}
        onSetSelectedOldImplants={setSelectedOldImplants}
      />}

      {isDispositionModalOpen && <BatchDispositionModal
        isAutoExplant
        isOpen={isDispositionModalOpen}
        scan={selectedOldImplants[0]}
        selectedScans={selectedOldImplants}
        assetType="explanted"
        dispositionStatus="EXPLANTED"
        isManualAddition={false}
        activeTray={surgery?.activeAssetTray || undefined}
        assetTrays={surgery?.assetTrays || []}
        onAutoExplantSave={handleAutoExplantSave}
        onSaveMedia={handleSaveUploadedMedia}
        onClose={() => {
          setIsDispositionModalOpen(false)
        }}
        isSubmitting={loadingBatchAddAssets}
        setIsTrayScannerOpen={() => {}}
      />}
    </div>
  )
}

export default MainIconLanding

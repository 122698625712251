import { CircularProgress } from '@mui/material'
import { CameraAlt, FlipCameraIos } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from 'components/mui'
import { Typography } from '@mui/material'
import classNames from 'classnames'

import SubmitImageDialog from './SubmitImageDialog/SubmitImageDialog'
import ManualAddModal from './ManualAddModal/ManualAddModal'
import InventoryProvider from 'lib/apollo/inventory-config'
import useCaptureCamera from './CaptureCamera.logic'
import { BackOrButton } from 'components/BackOrButton'
import { ArrowBack } from '@mui/icons-material'

import './CaptureCamera.scss'

const CaptureCamera = () => {
  const { surgeryId } = useParams()
  const {
    videoRef,
    mediaStream,
    videoHeight,
    orientation,
    isCameraMenuOpen,
    cameras,
    activeCamera,
    handleSelectCamera,
    handleToggleCameraMenu,
    handleManualAddClick,
    handleSubmitModalClose,
    isSubmitModalOpen,
    handleUsageCaptureImage,
    image,
    setImage,
    manualAddModalOpen,
  } = useCaptureCamera()

  if (videoRef === null || mediaStream === null) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <div className="header-buttons">
        {surgeryId && (
          <BackOrButton
            variant="outlined"
            className="back_button"
            size="small"
            fallbackUrl={`/surgeries/${surgeryId}/assigned-digital-trays`}
            fallbackChildren={
              <>
                <ArrowBack sx={{ mr: 0.5 }} /> BACK
              </>
            }
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </BackOrButton>
        )}
      </div>
      <div className="camera-container" style={{ height: videoHeight }}>
        {videoRef === null || mediaStream === null ? (
          <CircularProgress className="spinner" />
        ) : null}

        <video
          className="camera-feed"
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />

        <div
          className="buttons-container"
          style={{
            height: videoHeight,
            justifyContent: orientation === 'landscape' ? 'center' : 'flex-end',
          }}
        >
          <div className="camera-options_container">
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={handleToggleCameraMenu}
              startIcon={<FlipCameraIos />}
              className="camera-switch"
            >
              Switch Camera
            </LoadingButton>

            {isCameraMenuOpen && (
              <div className="camera-options">
                {cameras.map((camera: any) => (
                  <div
                    key={camera.deviceId}
                    className={classNames('camera-option', {
                      active: camera.deviceId === activeCamera?.deviceId,
                    })}
                    onClick={() => handleSelectCamera(camera)}
                  >
                    <Typography className="label">{camera.label}</Typography>
                    <Typography className="type">
                      {camera?.cameraType}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="capture-buttons">
            <LoadingButton
              className="side-button manual-add"
              onClick={handleManualAddClick}
              loading={false}
              variant="text"
            >
              Manually Add Image
            </LoadingButton>

            <LoadingButton
              className="capture"
              onClick={() => handleUsageCaptureImage()}
              loading={false}
              variant="text"
            >
              <CameraAlt className="icon" />
            </LoadingButton>
          </div>
        </div>
      </div>

      <InventoryProvider>
        <SubmitImageDialog
          open={isSubmitModalOpen}
          trayImage={image}
          setTrayImage={setImage}
          handleClose={handleSubmitModalClose}
        />
        {manualAddModalOpen && (
          <ManualAddModal
            open={manualAddModalOpen}
            onClose={handleManualAddClick}
          />
        )}
      </InventoryProvider>
    </>
  )
}

export default CaptureCamera

import { gql } from '@apollo/client'

export const GET_STATUS_QUERY = gql`
  query GET_STATUS {
    getStatus {
      status
    }
  }
`

export const GET_SURGERIES_QUERY = gql`
  query GET_SURGERIES($filters: Query_getSurgeries_Filter_Input) {
    getSurgeries(filters: $filters) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        count
      }
      results {
        addendums {
          text
        }
        isStarted
        isManuallyAdded
        _id
        visit {
          visitNumber
          visitDateTime
          attendingProvider {
            _id
            firstName
            lastName
            middleName
            phoneNumber
            emailAddresses
          }
          location {
            department
            room
            bed
          }
        }
        surgicalCase {
          number
          status
        }
        surgeryStaff {
          firstName
          lastName
          credentials
          roleDescription
        }
        patient {
          _id
          idMR
          firstName
          lastName
          middleName
          dateOfBirth
        }
        status {
          name
          user
          dateTime
        }
        procedures {
          dateTime
          duration
          description
          procedureInfo {
            description
            value
          }
        }
        authorizedReps {
          id
          accessLevel
        }
        authorizedCompanies {
          id
          accessLevel
        }
        assetTrays
        instrumentTrays
        activeAssetTray
        assetGroups {
          _id
          bidAssetId
          bidCompanyId
          deviceDescription
          # companyName
          deviceId
          deviceCount
          assetTray
          # versionModelNumber
          # sizeText
          packsUsed
          implanted
          wasted
          total
          isManualAddition
          accurateDeviceCount
          deviceCountCorrectiveActionTaken
          actualDeviceCount
          memo
          totalCost
          scans {
            _id
            bidAssetId
            bidAssetInstanceId
            assetType
            count
            catalogNumber
            category
            deviceDescription
            deviceId
            deviceCount
            deviceCountIndex
            expirationDate
            # gudidData // When queried, use JSON.parse(gudidData)
            implantSite
            implantStatus
            implantTime
            temperatureOk
            packagingOk
            samplePreparation
            samplePreparationNote
            implantMemo
            lotBatch
            companyName
            manufacturingDate
            sizeText
            sizeString
            serialNumber
            secondaryDeviceIdType
            versionModelNumber
            udi
            wastedReason
            media
            status {
              name
              userName
              userId
              bidCompanyId
              signature {
                signature
                date
              }
              sendToCompany
            }
            cost
            usedItemsCost
            chargeable
          }
        }
        isTestProcedure
        undocumentedScansCount
        totalSurgeryCost
        implantReportStatus {
          type
          isSent
        }
        salesRepsNotified {
          actionType
          salesReps {
            name
            auth0Id
            email
            phoneNumber
            sendEmail
            sendSms
            emailSent
            smsSent
            smsSentAt
            emailSentAt
            lastNotificationAttemptAt
          }
        }
      }
    }
  }
`

export const GET_SURGERY_QUERY = gql`
  query GET_SURGERY($surgeryId: ID!, $repCompanyIds: [Int]) {
    getSurgery(id: $surgeryId, repCompanyIds: $repCompanyIds) {
      isAutopopulateConsumableOptionSelected
      isStarted
      hasPreferenceCardConsumables
      autoPopulatedConsumables {
        bidProductId
        qty
      }
      _id
      addendums {
        text
        createdAt
        createdBy
      }
      assetTrays
      activeAssetTray
      instrumentTrays
      authorizedReps {
        id
        accessLevel
        bidCompanyIds
      }
      authorizedCompanies {
        id
        accessLevel
      }
      sentToSalesRep {
        company
        emails
        phoneNumbers
      }
      visit {
        visitNumber
        visitDateTime
        attendingProvider {
          _id
          firstName
          lastName
          middleName
          phoneNumber
          emailAddresses
        }
        location {
          department
          room
          bed
        }
      }
      surgicalCase {
        number
        status
      }
      surgeryStaff {
        firstName
        lastName
        credentials
        roleDescription
      }
      patient {
        _id
        idMR
        firstName
        lastName
        middleName
        dateOfBirth
      }
      status {
        name
        user
        scrubTechnician
        dateTime
      }
      statuses {
        name
        user
        scrubTechnician
        dateTime
      }
      procedures {
        dateTime
        duration
        description
        procedureInfo {
          description
          value
        }
      }
      assetGroups {
        _id
        bidAssetId
        bidCompanyId
        deviceDescription
        deviceId
        deviceCount
        assetTray
        packsUsed
        implanted
        wasted
        total
        isManualAddition
        accurateDeviceCount
        deviceCountCorrectiveActionTaken
        actualDeviceCount
        memo
        totalCost
        wastedReason
        scans {
          _id
          isManualAddition
          bidAssetId
          bidAssetInstanceId
          assetType
          count
          catalogNumber
          category
          deviceDescription
          deviceId
          deviceCount
          deviceCountIndex
          expirationDate
          implantSite
          siteLocation
          implantStatus
          implantTime
          temperatureOk
          packagingOk
          samplePreparation
          samplePreparationNote
          implantMemo
          lotBatch
          companyName
          manufacturingDate
          sizeText
          sizeString
          serialNumber
          secondaryDeviceIdType
          versionModelNumber
          udi
          wastedReason
          explantedReason
          explantedReasonNote
          media
          parentCompany
          parentCompanyId
          status {
            name
            userName
            userId
            bidCompanyId
            signature {
              signature
              date
              note
            }
            sendToCompany
          }
          cost
          usedItemsCost
          chargeable
          isDTMScrew
          dtmScrewData {
            column
            row
            size
            label
            wasted
            trayId
          }
          createdAt
          originalImplantAssetId
        }
      }
      isTestProcedure
      totalSurgeryCost
      implantReportStatus {
        type
        isSent
        referringPhysicianLastName
        referringPhysicianEmail
        implantReport
        attachments {
          filename
          bucketKey
        }
        createdAt
      }
      implantRecordRegistration {
        bidAssetId
        emails
      }
      implantableOtherRepReconciliation {
        assetDetails {
          assetTitle
          deviceId
          lotNumber
          modelNumber
          expirationDate
          deviceCount
          usedCount
        }
        assetId
        emails
        mobiles
      }
      explantedRepReconciliation {
        assetDetails {
          assetTitle
          deviceId
          lotNumber
          modelNumber
          expirationDate
          deviceCount
          usedCount
        }
        assetId
        emails
        mobiles
      }
      salesRepsNotified {
        actionType
        salesReps {
          name
          auth0Id
          email
          phoneNumber
          sendEmail
          sendSms
          emailSent
          smsSent
          smsSentAt
          emailSentAt
          lastNotificationAttemptAt
        }
      }
    }
  }
`

export const ADD_SURGERY_ASSET_SCANS_MUTATION = gql`
  mutation ADD_SURGERY_ASSET_SCANS_MUTATION(
    $scans: AddSurgeryAssetScansInput!
  ) {
    addSurgeryAssetScans(scans: $scans) {
      scans {
        _id
        # bidAssetId
        # bidAssetInstanceId
      }
    }
  }
`

export const UPDATE_SURGERY_ASSET_GROUPS_COUNTS_MUTATION = gql`
  mutation UPDATE_SURGERY_ASSET_GROUPS_COUNTS_MUTATION(
    $assetGroupsCounts: [Asset_Groups_Counts_Input]!
  ) {
    updateSurgeryAssetGroupsCounts(assetGroupsCounts: $assetGroupsCounts) {
      _id
      accurateDeviceCount
      deviceCountCorrectiveActionTaken
      actualDeviceCount
      memo
    }
  }
`

export const ADD_SURGERY_INSTRUMENT_TRAYS_MUTATION = gql`
  mutation ADD_SURGERY_INSTRUMENT_TRAYS_MUTATION(
    $surgeryId: ID!
    $instrumentTrays: [ID]!
  ) {
    addSurgeryInstrumentTraysScans(
      surgeryId: $surgeryId
      instrumentTrays: $instrumentTrays
    ) {
      _id
      instrumentTrays
    }
  }
`

export const SET_SURGERY_STATUS_MUTATION = gql`
  mutation SET_SURGERY_STATUS_MUTATION(
    $surgeryId: ID!
    $status: Procedure_Status_Kind!
    $rep: String
    $dateTime: AWSDateTime
    $scrubTechnician: ID
  ) {
    setSurgeryStatus(
      surgeryId: $surgeryId
      status: $status
      rep: $rep
      dateTime: $dateTime
      scrubTechnician: $scrubTechnician
    ) {
      _id
    }
  }
`

export const SET_ASSET_STATUS_MUTATION = gql`
  mutation SET_ASSET_STATUS_MUTATION(
    $assetIds: [ID!]!
    $status: Asset_Status_Kind!
    $userName: String!
    $sendToCompany: String
    $signature: Asset_Status_Signature_Input
  ) {
    setAssetsStatus(
      assetIds: $assetIds
      status: $status
      userName: $userName
      sendToCompany: $sendToCompany
      signature: $signature
    ) {
      _id
    }
  }
`

export const RESET_SURGERY_STATUS_MUTATION = gql`
  mutation RESET_SURGERY_STATUS_MUTATION($surgeryId: ID!) {
    resetSurgeryStatus(surgeryId: $surgeryId) {
      _id
    }
  }
`

export const DELETE_SURGERY_ASSETS_BY_IDS = gql`
  mutation DELETE_SURGERY_ASSETS_BY_IDS($surgeryId: ID!, $assetIds: [ID!]!) {
    deleteSurgeryAssetsByIds(surgeryId: $surgeryId, assetIds: $assetIds) {
      _id
    }
  }
`

export const DELETE_SURGERY_INSTRUMENT_TRAY = gql`
  mutation DELETE_SURGERY_INSTRUMENT_TRAY(
    $surgeryId: ID!
    $instrumentTray: ID!
  ) {
    deleteSurgeryInstrumentTray(
      surgeryId: $surgeryId
      instrumentTray: $instrumentTray
    ) {
      _id
      instrumentTrays
    }
  }
`

export const ALL_COMPANIES_QUERY = gql`
  query ALL_COMPANIES_QUERY {
    getCompanies {
      _id
      name
      productRegistrationEmail
    }
  }
`

export const ALL_SURGERY_COMPANIES_QUERY = gql`
  query ALL_SURGERY_COMPANIES_QUERY($surgeryId: ID!) {
    getSurgeryCompanies(surgeryId: $surgeryId) {
      id
      fromAssetInstanceId
      name
      regEmail
    }
  }
`

export const GET_PRODUCT_REPS_QUERY = gql`
  query GET_PRODUCT_REPS_QUERY($bidCompanyId: String) {
    getProductReps(bidCompanyId: $bidCompanyId) {
      id
      auth0id
      firstName
      lastName
      email
      mobileNumber
    }
  }
`

export const SEND_SURGERY_TO_REP_MUTATION = gql`
  mutation SEND_SURGERY_TO_REP_MUTATION(
    $surgeryId: ID!
    $repId: String!
    $email: String!
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $companyName: String
    $origin: String!
    $type: SendType = EMAIL
  ) {
    sendSurgeryToRep(
      surgeryId: $surgeryId
      repId: $repId
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      origin: $origin
      type: $type
    ) {
      success
    }
  }
`

export const GET_ACCESS_LOGS_QUERY = gql`
  query GET_ACCESS_LOGS_QUERY(
    $filter: AccessLogFilterInput
    $page: Int
    $limit: Int
  ) {
    getAccessLogs(filter: $filter, page: $page, limit: $limit) {
      pageInfo {
        totalCount
        currentPage
        hasNextPage
        hasPreviousPage
        count
      }
      results {
        _id
        operation
        # inputData
        user {
          _id
          firstName
          lastName
          email
          kind
        }
        createdAt
        inputData
        outputData
      }
    }
  }
`

export const SEND_PRODUCT_REP_INVITE_MUTATION = gql`
  mutation SendProductRepInvite(
    $email: String!
    $bidCompanyId: Int
    $surgeryId: ID
    $accessLevel: Int
    $firstName: String
    $lastName: String
    $mobile: String
    $companyName: String
    $resultUrl: String
  ) {
    sendProductRepInvite(
      email: $email
      bidCompanyId: $bidCompanyId
      surgeryId: $surgeryId
      accessLevel: $accessLevel
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      companyName: $companyName
      resultUrl: $resultUrl
    ) {
      success
      message
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation DELETE_USER_MUTATION($id: ID!) {
    deleteUser(_id: $id) {
      _id
    }
  }
`

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation RESET_USER_PASSWORD_MUTATION($email: String!) {
    resetUserPassword(email: $email) {
      success
    }
  }
`

export const GET_PROVIDERS_QUERY = gql`
  query {
    getProviders {
      _id
      idNPI
      firstName
      lastName
      middleName
      phoneNumber
      emailAddresses
      credentials
    }
  }
`

export const BETTERID_LOOKUP_QUERY = gql`
  query Assets($lookupValue: String!) {
    betterIdLookup(lookupValue: $lookupValue) {
      assets {
        catalogNumber
        company {
          id
          name
          dunsNumber
          parentCompanyId
          parentCompany {
            id
            isbt128Id
            name
            parentCompanyId
          }
        }
        companyId
        cost
        chargeable
        deviceDescription
        deviceCount
        deviceId
        fromAssetInstanceId
        id
        issuingAgency
        pkgQuantity
        sizeString
        sizeText
        secondaryDeviceId
        secondaryDeviceIdPkgQuantity
        secondaryDeviceIdPkgType
        secondaryDeviceIdType
        sourceId
        versionModelNumber
        gmdnPTDefinition
      }
      productionIdentifier {
        expirationDate
        lotBatch
        manufacturingDate
        serialNumber
      }
      term {
        isUDI
        value
      }
    }
  }
`

export const CHARACTER_SCAN_QUERY = gql`
  query CHARACTER_SCAN_QUERY($base64ImageStr: String!) {
    characterScan(base64ImageStr: $base64ImageStr) {
      textAnnotations
    }
  }
`

export const UPLOAD_IMAGE_MUTATION = gql`
  mutation UPLOAD_IMAGE_MUTATION(
    $base64ImageStr: String!
    $imageName: String!
  ) {
    uploadImage(base64ImageStr: $base64ImageStr, imageName: $imageName) {
      status
    }
  }
`

export const COMPANY_SEARCH_QUERY = gql`
  query COMPANY_SEARCH_QUERY($name: String, $companyIds: [Int!]) {
    companySearch(name: $name, companyIds: $companyIds) {
      id
      name
      dunsNumber
    }
  }
`

export const PAGINATED_COMPANY_SEARCH = gql`
  query companySearchPaginated($data: PaginatedCompanySearchInput!) {
    companySearchPaginated(data: $data) {
      companies {
        id
        name
        dunsNumber
        isbt128Id
        sourceId
        fromAssetInstanceId
        regEmail
        createdAt
        updatedAt
        parentCompanyId
        parentCompanyName
      }
      totalCount
    }
  }
`

export const BETTERID_LOOKUP_WITH_METADATA = gql`
  query BetterIdLookupByModelOrDD($data: BetterIdSearchByVMNAndDDInput!) {
    betterIdLookupByModelOrDD(data: $data) {
      totalCount
      products {
        catalogNumber
        companyId
        deviceDescription
        deviceCount
        deviceId
        fromAssetInstanceId
        id
        issuingAgency
        pkgQuantity
        sizeString
        sizeText
        secondaryDeviceId
        secondaryDeviceIdPkgQuantity
        secondaryDeviceIdPkgType
        secondaryDeviceIdType
        sourceId
        versionModelNumber
        gmdnPTDefinition
        cost
        chargeable
        company {
          id
          name
          dunsNumber
          parentCompanyId
          parentCompany {
            id
            isbt128Id
            name
            parentCompanyId
          }
        }
      }
    }
  }
`

export const GET_IMPLANT_SITES_QUERY = gql`
  query GET_IMPLANT_SITES_QUERY {
    getImplantSites {
      id
      name
    }
  }
`

export const DELETE_CONSUMABLE_ASSETS_MUTATION = gql`
  mutation DELETE_CONSUMABLE_ASSETS_MUTATION(
    $assetIds: [ID!]!
    $count: Int!
    $isMultipack: Boolean
  ) {
    deleteConsumableAssetsByIds(
      assetIds: $assetIds
      count: $count
      isMultipack: $isMultipack
    ) {
      success
      message
    }
  }
`

export const GET_PRODUCT_COMPANIES_QUERY = gql`
  query GET_PRODUCT_COMPANIES_QUERY($companyNames: [String]) {
    getProductCompanies(companyNames: $companyNames) {
      _id
      name
      productRegistrationEmail
    }
  }
`

export const GET_PATIENTS = gql`
  query GetPatients($filter: PatientsFilter_Input) {
    getPatients(filter: $filter) {
      _id
      lastName
      firstName
      idMR
      dateOfBirth
    }
  }
`

export const SEND_PRODUCT_REGISTRATION_EMAILS_MUTATION = gql`
  mutation SEND_PRODUCT_REGISTRATION_EMAILS_MUTATION(
    $registrationInputs: [ProductRegistrationEmailInput]
  ) {
    sendProductRegistrationEmails(registrationInputs: $registrationInputs) {
      success
    }
  }
`

export const ATTACH_MEDIA_MUTATION = gql`
  mutation ATTACH_MEDIA_MUTATION($assetId: String!, $media: [Media_Input]!) {
    attachMedia(assetId: $assetId, media: $media) {
      status
    }
  }
`

export const DOWNLOAD_MEDIA_QUERY = gql`
  query DOWNLOAD_MEDIA_QUERY($media: [Media_Input]!) {
    downloadMedia(media: $media) {
      filename
      size
      type
      url
      srcBase64Str
    }
  }
`

export const ADD_UNDOCUMENTED_SCAN_MUTATION = gql`
  mutation ADD_UNDOCUMENTED_SCAN_MUTATION(
    $surgeryId: ID!
    $userId: ID!
    $data: [UndocumentedScanData]!
  ) {
    addUndocumentedScan(surgeryId: $surgeryId, userId: $userId, data: $data) {
      _id
      bidAssetId
      bidAssetInstanceId
      catalogNumber
      deviceDescription
      deviceId
      deviceCount
      pkgQuantity
      expirationDate
      lotBatch
      companyName
      manufacturingDate
      versionModelNumber
      serialNumber
      sizeText
      sizeString
      udi
      issuingAgency
      secondaryDeviceIdType
      assetType
      gmdnPTDefinition
      cost
      chargeable
      isDTMScrew
      dtmScrewData {
        column
        row
        size
        label
        wasted
        trayId
      }
      isExplanted
      originalImplantAssetId
      originalImplantSite
      originalSiteLocation
    }
  }
`

export const GET_UNDOCUMENTED_SCANS = gql`
  query GET_UNDOCUMENTED_SCANS($surgeryId: ID!, $userId: ID) {
    getUndocumentedScans(surgeryId: $surgeryId, userId: $userId) {
      _id
      bidAssetId
      bidAssetInstanceId
      bidCompanyId
      catalogNumber
      deviceDescription
      deviceId
      count
      deviceCount
      pkgQuantity
      expirationDate
      lotBatch
      companyName
      manufacturingDate
      versionModelNumber
      serialNumber
      sizeText
      sizeString
      udi
      issuingAgency
      secondaryDeviceIdType
      # isDocumented
      userId
      assetType
      gmdnPTDefinition
      cost
      chargeable
      createdAt
      updatedAt
      isManualAddition
      isDTMScrew
      isRepAddition
      parentCompany
      parentCompanyId
      dtmScrewData {
        column
        row
        size
        label
        wasted
        trayId
      }
      isExplanted
      originalImplantAssetId
      originalImplantSite
      originalSiteLocation
    }
  }
`

export const DELETE_UNDOCUMENTED_SCANS = gql`
  mutation DELETE_UNDOCUMENTED_SCANS($scanIds: [ID!]!) {
    deleteUndocumentedScans(scanIds: $scanIds) {
      deletedIds
    }
  }
`

export const BATCH_ADD_ASSETS_TO_SURGERY = gql`
  mutation BATCH_ADD_ASSETS_TO_SURGERY(
    $surgeryId: ID!
    $disposition: BatchScanDispositionInput
    $dispositions: [BatchScanDispositionInput!]
    $scans: [BatchScanAssetsInput!]!
    $deleteUndocumentedScansIds: [ID!]
  ) {
    batchAddAssetsToSurgery(
      surgeryId: $surgeryId
      disposition: $disposition
      dispositions: $dispositions
      scans: $scans
      deleteUndocumentedScansIds: $deleteUndocumentedScansIds
    ) {
      addedScanIds
    }
  }
`

export const GET_ASSET_MEDIA_FILENAMES_QUERY = gql`
  query GET_ASSET_MEDIA_FILENAMES_QUERY($assetId: String!) {
    getAssetMediaFilenames(assetId: $assetId) {
      filenames
    }
  }
`

export const SEARCH_REP_USERS_QUERY = gql`
  query SEARCH_REP_USERS_QUERY($name: String, $bidCompanyId: Int) {
    searchRepUsers(name: $name, bidCompanyId: $bidCompanyId) {
      id
      email
      emailVerified
      firstName
      lastName
      bidCompanyId
    }
  }
`

export const GET_RECORD_COMPANY_REPS_QUERY = gql`
  query GET_RECORD_COMPANY_REPS_QUERY($surgeryId: ID!) {
    getRecordCompanyReps(surgeryId: $surgeryId) {
      name
      bidCompanyId
      reps {
        id
        email
        phoneNumber
        firstName
        lastName
        bidCompanyId
      }
    }
  }
`

export const CREATE_BETTERID_PRODUCT_EMAIL = gql`
  mutation BetterIdCreateProductEmail($productId: Int!, $email: String!) {
    betterIdCreateProductEmail(productId: $productId, email: $email) {
      message
    }
  }
`

export const FIND_BETTERID_PRODUCT_EMAILS = gql`
  query BetterIdFindProductsEmails($productsIds: [Int!]!) {
    betterIdFindProductsEmails(productsIds: $productsIds) {
      productId
      emails {
        emailId
        productEmailId
        email
      }
    }
  }
`

export const UPDATE_BETTERID_PRODUCT_EMAIL = gql`
  mutation BetterIdUpdateProductEmail($productEmailId: Int!, $email: String!) {
    betterIdUpdateProductEmail(productEmailId: $productEmailId, email: $email) {
      message
    }
  }
`

export const DELETE_BETTERID_PRODUCT_EMAIL = gql`
  mutation BetterIdDeleteProductEmail($productEmailId: Int!) {
    betterIdDeleteProductEmail(productEmailId: $productEmailId) {
      message
    }
  }
`

// Inventory Management queries
export const GET_ALL_TRAY_ANALYSES = gql`
  query GetAllTrayAnalyses($input: GetTrayAnalyses!) {
    getAllTrayAnalyses(input: $input) {
      trayAnalyses {
        id
        tray_img
        processing_time
        accuracy_rate
        surgery_id
        user_id
        error
        createdAt
        updatedAt
        analysis_results {
          column
          size
          row
          label
          wasted
        }
        user_corrections {
          column
          size
          row
          label
          wasted
        }
      }
      totalCount
    }
  }
`

export const CREATE_TRAY_ANALYSIS = gql`
  mutation CreateTrayAnalysis($input: TrayAnalysisInput!) {
    createTrayAnalysis(input: $input) {
      id
      tray_img
      analysis_results {
        column
        row
        size
        label
        wasted
      }
      user_corrections {
        column
        row
        size
        label
        wasted
      }
      processing_time
      accuracy_rate
      surgery_id
      user_id
      error
      createdAt
      updatedAt
    }
  }
`

export const UPLOAD_TRAY_IMAGE_TO_S3_BUCKET = gql`
  mutation UploadTrayImageToS3Bucket($input: TrayImageUploadInput!) {
    uploadTrayImageToS3Bucket(input: $input) {
      tray_img
    }
  }
`

export const UPDATE_USER_CORRECTIONS = gql`
  mutation UpdateUserCorrections(
    $trayId: Int!
    $userCorrections: [AnalysisResultInput!]!
  ) {
    updateUserCorrections(
      input: { trayId: $trayId, userCorrections: $userCorrections }
    ) {
      id
      tray_img
      analysis_results {
        column
        row
        size
        label
        wasted
      }
      user_corrections {
        column
        row
        size
        label
        wasted
      }
      processing_time
      accuracy_rate
      surgery_id
      user_id
      createdAt
      updatedAt
    }
  }
`

export const DELETE_TRAY = gql`
  mutation DeleteTray($trayId: Int!) {
    deleteTray(input: { trayId: $trayId })
  }
`

export const EDIT_DOCUMENTED_ASSETS = gql`
  mutation EDIT_DOCUMENTED_ASSETS(
    $surgeryId: ID!
    $disposition: BatchScanDispositionInput!
    $scans: [EditDocumentedScanInput!]!
    $isSponge: Boolean
  ) {
    editDocumentedAssets(
      surgeryId: $surgeryId
      disposition: $disposition
      scans: $scans
      isSponge: $isSponge
    ) {
      success
      message
    }
  }
`

export const GENERATE_PRESIGNED_URL_MUTATION = gql`
  mutation GeneratePresignedUrl($key: String!) {
    generatePresignedUrl(key: $key)
  }
`
export const DELETE_TRAY_ITEM = gql`
  mutation DeleteTrayItem($trayId: Int!) {
    deleteTrayItem(input: { trayId: $trayId })
  }
`

export const GET_TRAY_ITEMS = gql`
  query GetTrayItems {
    getTrayItems {
      id
      bidProductId
      deviceId
      catalogNumber
      issuingAgency
      companyName
      companyId
      lotBatch
      description
      versionModelNumber
      productDetails
      serialNumber
      locationId
      expirationDate
      manufacturingDate
      barcode
      isTray
      subItems {
        id
        productDetails
        description
        companyName
      }
      isConsigned
      category
      quantity
      createdAt
      updatedAt
    }
  }
`

export const GET_SURGERY_TRAYS = gql`
  query GetSurgeryItems($surgeryId: String!) {
    getSurgeryItems(surgeryId: $surgeryId) {
      id
      bidProductId
      deviceId
      catalogNumber
      issuingAgency
      companyName
      companyId
      lotBatch
      description
      versionModelNumber
      productDetails
      subItems {
        id
        productDetails
        description
        companyName
      }
      serialNumber
      expirationDate
      manufacturingDate
      barcode
      isTray
      isConsigned
      category
      quantity
      createdAt
      updatedAt
    }
  }
`

export const EDIT_TRAY_ITEM = gql`
  mutation EditTrayItem($input: EditTrayItemInput!) {
    editTrayItem(input: $input)
  }
`

export const EDIT_TRAY_ITEM_JSON_DETAILS = gql`
  mutation EditTrayItemJSONDetails($input: EditTrayItemJSONDetails!) {
    editTrayItemJSONDetails(input: $input)
  }
`

export const GET_TRAY_ITEM_BY_BARCODE = gql`
  query GetTrayItemByBarcode($barcode: String!) {
    getTrayItemByBarcode(barcode: $barcode) {
      id
      bidProductId
      deviceId
      catalogNumber
      issuingAgency
      companyName
      companyId
      lotBatch
      locationId
      description
      versionModelNumber
      serialNumber
      expirationDate
      manufacturingDate
      barcode
      isTray
      isConsigned
      category
      productDetails
      quantity
      createdAt
      updatedAt
      subItems {
        id
        productDetails
        description
        companyName
      }
    }
  }
`

export const CREATE_TRAY_ITEM_MUTATION = gql`
  mutation CreateTrayItem($input: CreateTrayItemInput!) {
    createTrayItem(input: $input) {
      id
      companyName
      companyName
      locationId
      description
      barcode
      isConsigned
      productDetails
      quantity
      catalogNumber
      category
      companyId
      expirationDate
      subItems {
        id
        companyName
        description
        productDetails
      }
    }
  }
`

export const SEND_IMPLANT_REPORT_EMAIL_MUTATION = gql`
  mutation sendImplantReportEmail(
    $implantReportEmailInput: ImplantReportEmailInput!
  ) {
    sendImplantReportEmail(implantReportEmailInput: $implantReportEmailInput) {
      success
      message
    }
  }
`

// Rep Invite
export const GET_INVITE_REP_QR_CODE = gql`
  query GetInviteRepQrCode($canAddAsset: Boolean, $surgeryId: String) {
    getInviteRepQrCode(canAddAsset: $canAddAsset, surgeryId: $surgeryId) {
      success
      message
      data {
        jwt
      }
    }
  }
`
export const VALIDATE_INVITE_REP_QR_CODE = gql`
  query ValidateInviteRepQrCode($token: String) {
    validateInviteRepQrCode(token: $token) {
      success
      message
      data {
        canAddAsset
        surgeryId
      }
    }
  }
`
export const ADD_REP_TO_SURGERY = gql`
  mutation AddRepToSurgery(
    $surgeryId: ID!
    $repId: String!
    $accessLevel: Int!
  ) {
    addRepToSurgery(
      surgeryId: $surgeryId
      repId: $repId
      accessLevel: $accessLevel
    ) {
      success
      message
    }
  }
`

export const GET_ASSET_MEDIA_FILENAMES = gql`
  query GetMediaFiles($filenames: [String!]!) {
    getMediaFiles(filenames: $filenames) {
      content
      filename
    }
  }
`

export const DELETE_MEDIA_FILES = gql`
  mutation DeleteMediaFiles(
    $filenames: [String]!
    $assetId: String
    $scanId: String
  ) {
    deleteMediaFiles(
      filenames: $filenames
      assetId: $assetId
      scanId: $scanId
    ) {
      status
    }
  }
`

export const UPLOAD_ASSET_MEDIA = gql`
  mutation UploadAssetMedia(
    $files: [UploadFileObject!]!
    $assetId: String
    $scanId: String
  ) {
    uploadAssetMedia(files: $files, assetId: $assetId, scanId: $scanId) {
      message
      files {
        filename
        base64Content
      }
    }
  }
`

export const GET_REFERRING_PHYSICIANS = gql`
  query GetPhysicians($search: String, $limit: Int, $skip: Int) {
    getPhysicians(search: $search, limit: $limit, skip: $skip) {
      physicians {
        _id
        email
        firstName
        lastName
        speciality
        mobile
      }
      totalCount
    }
  }
`

export const GET_REFERRING_PHYSICIAN = gql`
  query getPhysician($physician: Physician!) {
    getPhysician(physician: $physician) {
      _id
      firstName
      lastName
      email
    }
  }
`

export const ADD_REFERRING_PHYSICIAN = gql`
  mutation createPhysician($physician: CreatePhysicianInput!) {
    createPhysician(physician: $physician) {
      _id
      firstName
      lastName
      email
      speciality
      mobile
    }
  }
`

export const ADD_SCRUB_TECHNICIAN = gql`
  mutation CreateScrubTechnician(
    $createScrubTechnicianInput: CreateScrubTechnicianInput!
  ) {
    createScrubTechnician(
      createScrubTechnicianInput: $createScrubTechnicianInput
    ) {
      success
      message
    }
  }
`

export const EDIT_REFERRING_PHYSICIAN = gql`
  mutation EditPhysician($physician: EditPhysicianInput) {
    editPhysician(physician: $physician) {
      _id
      firstName
      lastName
      email
      speciality
      mobile
    }
  }
`
export const DELETE_REFERRING_PHYSICIANS = gql`
  mutation deletePhysicians($physicianIds: [ID!]!) {
    deletePhysicians(physicianIds: $physicianIds) {
      success
      message
    }
  }
`

export const GET_REPS_QUERY = gql`
  query getReps($bidCompanyId: Int, $firstName: String, $lastName: String) {
    getReps(
      bidCompanyId: $bidCompanyId
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      message
      data {
        id
        bidCompanyId
        accessLevel
        inviteCode
        firstName
        lastName
        mobile
        companyName
        invitePending
        email
        siblingCompaniesIds
        subsidiariesCompaniesIds
        parentCompanyId
      }
    }
  }
`

export const SEND_ASSET_SMS_EMAIL_MUTATION = gql`
  mutation SendAssetSmsEmail(
    $surgeryId: ID!
    $rep: RepInput!
    $isSms: Boolean!
    $assetDetails: AssetDetails!
  ) {
    sendAssetSmsEmail(
      surgeryId: $surgeryId
      rep: $rep
      isSms: $isSms
      assetDetails: $assetDetails
    ) {
      success
      message
    }
  }
`

export const SET_SURGERY_STATUS_AS_STARTED = gql`
  mutation SetSurgeryAsStarted($surgeryId: ID!) {
    setSurgeryAsStarted(surgeryId: $surgeryId) {
      message
      success
    }
  }
`

export const CREATE_SURGERY = gql`
  mutation CreateSurgery($surgery: CreateSurgeryInput!) {
    createSurgery(surgery: $surgery) {
      success
      message
      data {
        _id
      }
    }
  }
`
export const GET_SUB_TRAY_ITEM_BY_ID = gql`
  query GetSubTrayItemById($subTrayItemId: Float!) {
    getSubTrayItemById(subTrayItemId: $subTrayItemId) {
      id
      companyName
      description
      productDetails
    }
  }
`

export const DELETE_SUB_TRAY_ITEM = gql`
  mutation DeleteSubTrayItem($subTrayItemId: Float!) {
    deleteSubTrayItem(subTrayItemId: $subTrayItemId)
  }
`

export const EDIT_SUB_TRAY_ITEM = gql`
  mutation EditSubTrayItem($input: EditSubTrayInput!) {
    editSubTrayItem(input: $input)
  }
`

export const ASSIGN_TRAY_STATUS_AS_CASE_COMPLETE = gql`
  mutation AssignTrayStatusAsCaseComplete($id: Float!) {
    assignTrayStatusAsCaseComplete(id: $id)
  }
`
export const CREATE_GUEST_MUTATION = gql`
  mutation CreateGuest($createGuestInput: CreateGuestArgs!) {
    createGuest(createGuestInput: $createGuestInput) {
      id
      name
      company
      phone
      email
    }
  }
`
export const ASSIGN_TRAY_AS_NURSE_FROM_OR = gql`
  mutation AssignTrayAsNurseFromOR($input: EditTrayItemInput!) {
    assignTrayAsNurseFromOR(input: $input)
  }
`

export const GET_SCRUB_TECHS_QUERY = gql`
  query getScrubTechnicians {
    getScrubTechnicians {
      success
      message
      data {
        _id
        orgId
        firstName
        lastName
        email
        speciality
      }
    }
  }
`

export const EDIT_SCRUB_TECHNICIAN = gql`
  mutation EditScubTechnician(
    $editScrubTechnicianInput: EditScrubTechnicianInput!
  ) {
    editScrubTechnician(editScrubTechnicianInput: $editScrubTechnicianInput) {
      message
      success
    }
  }
`

export const DELETE_SCRUB_TECHNITIANS = gql`
  mutation DeleteScrubTechnicians($scrubTechniciansIds: [String!]!) {
    deleteScrubTechnicians(scrubTechniciansIds: $scrubTechniciansIds) {
      success
      message
    }
  }
`

export const ADD_ADDENDUM = gql`
  mutation AddAddendum($input: AddAddendumInput!) {
    addAddendum(input: $input) {
      success
      message
    }
  }
`

export const SEND_SMS_TO_PHYSICIAN = gql`
  mutation SendSmsToPhysician($options: SendSmsToPhysicianInput!) {
    sendSmsToPhysician(options: $options) {
      success
      message
    }
  }
`

export const UPDATE_SURGERY_AUTOPOPULATE = gql`
  mutation UpdateSurgeryAutopopulate($input: UpdateSurgeryAutopopulateInput!) {
    updateSurgeryAutopopulate(input: $input) {
      success
      message
    }
  }
`

export const ADD_MISSING_FIELDS = gql`
  mutation AddMissingFields($assetId: String!, $fields: [AssetFieldInput!]!) {
    addMissingFields(assetId: $assetId, fields: $fields) {
      success
      message
    }
  }
`

export const GET_PATIENT_OLD_IMPLANTS = gql`
  query getPatientOldImplants($input: GetPatientOldImplantsInput!) {
    getPatientOldImplants(input: $input) {
      success
      implants {
        _id
        bidAssetId
        bidCompanyId
        bidAssetInstanceId
        assetType
        count
        catalogNumber
        category
        deviceDescription
        deviceId
        deviceCount
        deviceCountIndex
        expirationDate
        implantSite
        siteLocation
        implantStatus
        implantTime
        temperatureOk
        packagingOk
        samplePreparation
        samplePreparationNote
        implantMemo
        lotBatch
        companyName
        manufacturingDate
        sizeText
        sizeString
        serialNumber
        secondaryDeviceIdType
        versionModelNumber
        udi
        wastedReason
        media
        status {
          name
          userName
          userId
          bidCompanyId
          signature {
            signature
            date
          }
          sendToCompany
        }
        cost
        usedItemsCost
        chargeable
      }
      message
    }
  }
`

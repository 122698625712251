import { useUser } from 'app/User'
import { facility } from 'common/facilityMigration'
import {
  RepApprovalFormData,
  AssetStatus,
  Scan,
  ProcedureStatus,
  AssetData,
} from 'common/types'
import dayjs from 'lib/dayjs'
import {
  useSetSurgeryStatus,
  useSetAssetStatus,
  useLazyCompanySearchQuery,
  useAddAddendumMutation,
  useGetSurgeryQuery,
} from 'lib/apollo/hooks'
import { LONGPRESS_DEFAULT_OPTIONS } from 'lib/config'
import { useBottomNavigation } from 'lib/context/BottomNavigation'
import { isEqual } from 'lodash'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { usePrevious, useLongPress } from 'react-use'
import { groupAssetsByAssetType, getStatusDetails } from 'lib/utils/data'
import { getGroupedAssetData } from 'lib/utils/grouped-asset-data'
import {
  AssetListLogicProps,
  initialFormData,
  TAddendums,
  TAddendumsData,
} from './AssetList.types'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import RepApproval from './forms/repApproval/RepApproval'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { enqueueSnackbar } from 'notistack'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { TextField } from '@mui/material'
import styles from './assetList.module.css'
import { useIsMobile } from 'lib/utils/mediaQueries'
import moment from 'moment'
import './assetList.scss'

export const useAssetListLogic = (props: AssetListLogicProps) => {
  const { surgery, derivedSurgeryStatus } = props
  // hooks
  const {
    name: procedureStatus,
    workflow,
    hasImplantableHardware,
    hasImplantableBiologic,
    hasImplantableOther,
    totalHardwareCount,
    implantableScanIdsByStatus,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
  } = derivedSurgeryStatus

  const isMobile = useIsMobile()
  const { user, isNurse, isNurseAdmin, isRep } = useUser()
  const repBidCompanyId = isRep ? user?.bidCompanyId || null : null
  const repCompanyIds = useMemo(() => {
    return isRep
      ? [
          user?.bidCompanyId as number,
          ...(user?.parentCompanyId ? [user.parentCompanyId] : []),
          ...((user?.siblingCompaniesIds as number[]) ?? []),
          ...((user?.subsidiariesCompaniesIds as number[]) ?? []),
        ]
      : []
  }, [
    isRep,
    user?.bidCompanyId,
    user?.parentCompanyId,
    user?.siblingCompaniesIds,
    user?.subsidiariesCompaniesIds,
  ])

  const { patient, procedures, visit } = surgery
  const patientName = `${patient.firstName} ${patient.lastName}`
  const MRN = patient.idMR
  const DOB = patient.dateOfBirth
  const surgeryType = procedures[0]?.description
  const surgeon = `${visit?.attendingProvider?.firstName || ''} ${
    visit?.attendingProvider?.lastName || ''
  }`.trim()
  const dateTime = visit?.visitDateTime
  const visitNumber = visit?.visitNumber
  const authorizedReps = surgery?.authorizedReps?.map((rep) => rep.id)

  const printHeader = (
    <div className="header-section">
      <h2>Patient Information</h2>
      <hr />
      <div className="info-columns">
        <div className="column">
          <p className="highlight">
            <span>Patient Name:</span>{' '}
            <strong className="highlight">{patientName}</strong>
          </p>

          <p>
            <span>Surgeon:</span> <strong>{surgeon}</strong>
          </p>
          <p>
            <span>Visit Number:</span> <strong>{visitNumber}</strong>
          </p>
          <p>
            <span>Surgery Type:</span> <strong>{surgeryType}</strong>
          </p>
        </div>
        <div className="column">
          <p className="highlight">
            <span>MRN:</span> <strong className="highlight">{MRN}</strong>
          </p>
          <p>
            <span>Date of Birth:</span>{' '}
            <strong>{moment(DOB).format('MM/DD/YYYY')}</strong>
          </p>
          <p>
            <span>Date:</span>{' '}
            <strong>{moment(dateTime).format('MM/DD/YYYY [at] h:mm A')}</strong>
          </p>
        </div>
      </div>
    </div>
  )

  const [
    getRepCompanies,
    { data: repCompanies, loading: loadingRepCompanies },
  ] = useLazyCompanySearchQuery()

  useEffect(() => {
    if (repBidCompanyId) {
      getRepCompanies({
        variables: {
          companyIds: repCompanyIds,
        },
      })
    }
  }, [getRepCompanies, repBidCompanyId, repCompanyIds])

  const repCompanyNames = repCompanies?.companySearch.map(
    (company) => company.name
  )

  const { assetTypes } = facility
  const flags = useFlags()

  const [setSurgeryStatus, setSurgeryStatusMutation] = useSetSurgeryStatus(
    surgery._id
  )
  const [setSurgeryStatusSubmitted, setSurgeryStatusSubmittedMutation] =
    useSetSurgeryStatus(surgery._id)
  const [setAssetStatus, setAssetStatusMutation] = useSetAssetStatus(
    surgery._id
  )
  const [setAssetStatusSecondary, setAssetStatusSecondaryMutation] =
    useSetAssetStatus(surgery._id)

  const { resetBottomNavigation, setShowBottomNavigation } =
    useBottomNavigation()

  const [dtmHardwareAssetData, setDTMHardwareAssetData] = useState<AssetData[]>(
    []
  )

  const [addendums, setAddendums] = useState<TAddendums>({
    show: false,
    newAddendum: surgery?.addendums?.length > 0 ? null : '',
    addendumsData:
      surgery?.addendums
        ?.map((addendum) => ({
          ...addendum,
          createdAt: new Date(addendum.createdAt).toLocaleString(),
        }))
        .reverse() || [],
  })

  const [addAddendum, { loading: loadingAddendum }] = useAddAddendumMutation()
  const { refetch: updateSurgery, loading: updateSurgeryLoading } =
    useGetSurgeryQuery(surgery._id, {
      disablePolling: true,
    })

  const prevProcedureStatus = usePrevious(procedureStatus)
  const previousWorkflow = usePrevious(workflow)
  const onLongPress = () => setShowDemoMenu(true)
  const demoMenuEvent = useLongPress(onLongPress, LONGPRESS_DEFAULT_OPTIONS)

  const initialGroupedAssets = useMemo(
    () => groupAssetsByAssetType([], assetTypes),
    [assetTypes]
  )

  const groupedAssets = useMemo(
    () => groupAssetsByAssetType(surgery.assetGroups, assetTypes),
    [assetTypes, surgery.assetGroups]
  )
  const isGroupedAssetsEmpty = isEqual(groupedAssets, initialGroupedAssets)

  const groupedAssetData = useMemo(() => {
    return getGroupedAssetData(surgery.assetGroups)
  }, [surgery.assetGroups])

  // states
  const [isQrOpen, setIsQrOpen] = useState(false)
  const [showGenericAlertDialog, setShowGenericAlertDialog] = useState(false)

  const [alertDialogDetails, setAlertDialogDetails] = useState<any>({
    title: 'Products ready for approval',
    mode: 'info',
    description:
      'The nurse has marked these products as complete and ready for your approval.',
    primaryButtonAction: () => setShowGenericAlertDialog(false),
    primaryButtonText: 'Continue',
  })
  const [showDemoMenu, setShowDemoMenu] = useState(false)
  const [repApprovalFormData] = useState<RepApprovalFormData>(initialFormData)

  // constants
  let heading = 'Documented List'

  const isNurseUser = isNurse || isNurseAdmin

  const surgeryRepCount = surgery?.authorizedReps?.length || 0

  const statusSubmittedDetails = useMemo(
    () => getStatusDetails(surgery, 'SUBMITTED'),
    [surgery]
  )
  const timeSubmitted = statusSubmittedDetails?.dateTime

  const userName = `${user?.firstName} ${user?.lastName}`

  const submittedAndNurseUser = procedureStatus === 'SUBMITTED' && isNurseUser

  const enableSendToRep =
    procedureStatus === 'SCANNING' && hasImplantableHardware

  const enableImplantRecordSender =
    submittedAndNurseUser &&
    hasImplantableHardware &&
    flags.shareImplantRecord !== false

  const enableSendProductRegistration =
    submittedAndNurseUser && (hasImplantableBiologic || hasImplantableOther)

  const enableSendImplantReport =
    submittedAndNurseUser && flags.implantSurgeryReports !== false

  const renderCloseCase = isNurseUser && flags.closeCaseButton !== false
  const hidePrint =
    isRep ||
    procedureStatus !== 'SUBMITTED' ||
    !surgery ||
    loadingRepCompanies ||
    loadingRepCompanies ||
    setSurgeryStatusMutation.loading ||
    setAssetStatusSecondaryMutation.loading ||
    setAssetStatusMutation.loading
  // life cycle methods
  if (procedureStatus === 'SUBMITTED') {
    heading = 'Record Submitted to EMR'
  }
  if (procedureStatus === 'PRE_APPROVAL') {
    heading = 'Product Approval'
  }

  const handleSetProcedureStatus = useCallback(
    (status: ProcedureStatus['name'], overrideRepname?: string) => {
      setSurgeryStatus({
        variables: {
          surgeryId: surgery._id,
          status,
          rep: overrideRepname ?? repApprovalFormData.repName,
          dateTime: dayjs().toISOString(),
        },
      })
    },
    [surgery._id, repApprovalFormData.repName, setSurgeryStatus]
  )

  useEffect(() => {
    if (procedureStatus !== 'SCANNING' && procedureStatus !== 'APPROVED') {
      return setShowBottomNavigation(false)
    }
    resetBottomNavigation()
  }, [procedureStatus, resetBottomNavigation, setShowBottomNavigation])

  // Alerts users when procedure status changes
  useEffect(() => {
    if (isRep) {
      // Alerts rep when nurse cancels approval process
      if (
        prevProcedureStatus === 'PRE_APPROVAL' &&
        procedureStatus === 'SCANNING' &&
        workflow === 'rep' &&
        previousWorkflow !== 'no-rep'
      ) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'The approval process was cancelled by the nurse',
          mode: 'errorInfo',
          description:
            'You will be redirected to the product list screen while the nurse updates the record. When it is complete, you will be able to approve products again.',
          primaryButtonAction: () => {
            setShowGenericAlertDialog(false)
          },
          primaryButtonText: 'Continue',
        })
      }

      // Alerts rep when assets are ready for approval
      if (
        procedureStatus === 'PRE_APPROVAL' &&
        prevProcedureStatus !== 'PRE_APPROVAL' &&
        workflow === 'rep'
      ) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'Products ready for approval',
          mode: 'info',
          description:
            'The nurse has marked these products as complete and ready for your approval.',
          primaryButtonAction: () => setShowGenericAlertDialog(false),
          primaryButtonText: 'Continue',
        })
      }
    }

    if (isNurseUser && workflow === 'rep') {
      // Alerts nurse when rep approves assets
      if (procedureStatus === 'APPROVED' && hasImplantableHardware) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'Approval complete',
          mode: 'info',
          description: `All products have been approved, and it is now ready to submit to the EMR.`,
          primaryButtonAction: () => setShowGenericAlertDialog(false),
          primaryButtonText: 'Continue',
        })
      }
    }
  }, [
    handleSetProcedureStatus,
    hasImplantableHardware,
    hasImplantableBiologic,
    prevProcedureStatus,
    previousWorkflow,
    procedureStatus,
    isNurseUser,
    isRep,
    workflow,
  ])

  useEffect(() => {
    if (groupedAssetData) {
      handleGetDTMAssetData(groupedAssetData.hardware.nurseScans)
    }
  }, [groupedAssetData.hardware.nurseScans])

  // handles
  const handleGetDTMAssetData = (assetData: AssetData[]) => {
    try {
      if (!assetData) {
        throw new Error('Please pass the assetData array to the function.')
      }

      if (assetData.length === 0) {
        return
      }

      const dtmAssetData = assetData.filter((asset) =>
        asset.scans.some((scan) => scan.isDTMScrew)
      )

      setDTMHardwareAssetData(dtmAssetData)
    } catch (error) {
      console.error(
        "Couldn't get DTM asset data from the groupedAssetData array.",
        error
      )
    }
  }

  const handleSetAssetStatus = useCallback(
    (
      status: AssetStatus['name'],
      source = 'primary',
      sendToCompany: string | null = null,
      signature: AssetStatus['signature'] = null
    ) => {
      const sourceFunction =
        source === 'primary' ? setAssetStatus : setAssetStatusSecondary
      let assetIds: Scan['_id'][] = []

      const implantableScans = surgery.assetGroups
        .flatMap((asset: AssetData) =>
          asset.scans.map((scan) => ({
            id: scan._id,
            name: scan.deviceDescription,
            manufacturer: scan.companyName,
            bidCompanyId: asset.bidCompanyId,
            assetType: scan.assetType,
            status: scan.status.name,
          }))
        )
        .filter((scan) => scan.assetType === 'non-biological')

      const authorizedRepsBidCompanyIds = Array.from(
        new Set(
          surgery?.authorizedReps?.reduce((acc, rep) => {
            return [...acc, ...(rep?.bidCompanyIds ?? [])]
          }, [] as number[])
        )
      )

      const RepNonApprovedScans = implantableScans.filter(
        (scan) =>
          scan.status === 'SCANNED' &&
          authorizedRepsBidCompanyIds?.includes(scan.bidCompanyId ?? 0)
      )

      if (status === 'PRE_APPROVAL') {
        assetIds =
          source === 'primary'
            ? RepNonApprovedScans.map((scan) => scan.id)
            : implantableScanIdsByStatus.SCANNED
      } else if (status === 'APPROVED') {
        assetIds = implantableScanIdsByStatus.PRE_APPROVAL
      } else if (status === 'SCANNED') {
        assetIds = implantableScanIdsByStatus.PRE_APPROVAL
      }

      if (!assetIds) {
        throw Error('No products to update')
      }

      sourceFunction({
        variables: {
          assetIds,
          status,
          userName,
          sendToCompany,
          signature,
        },
      })
    },
    [
      implantableScanIdsByStatus.PRE_APPROVAL,
      implantableScanIdsByStatus.SCANNED,
      setAssetStatus,
      setAssetStatusSecondary,
      surgery.assetGroups,
      surgery?.authorizedReps,
      userName,
    ]
  )

  const handleCloseModal = () => {
    setIsQrOpen(false)
  }

  const handleCancelApproval = () => {
    setShowGenericAlertDialog(true)
    setAlertDialogDetails({
      title: 'Cancel the approval process?',
      mode: 'errorInfo',
      description:
        'Cancelling the approval process will return you to the product list screen where you can delete and re-capture products.',
      primaryButtonAction: () => {
        setShowGenericAlertDialog(false)
        handleSetAssetStatus('SCANNED', 'secondary')
      },
      primaryButtonText: 'Yes, cancel',
      dataTestIdPrimary: 'popup-cancel-button',
      secondaryButtonAction: () => setShowGenericAlertDialog(false),
      secondaryButtonText: 'Back',
    })
  }

  const handleSubmitRecord = useCallback(
    () =>
      setSurgeryStatusSubmitted({
        variables: {
          surgeryId: surgery._id,
          status: 'SUBMITTED',
          rep: userName,
          dateTime: dayjs().toISOString(),
        },
      }),
    [surgery._id, setSurgeryStatusSubmitted, userName]
  )

  const renderRecordApprovalForm = () => {
    return (
      <RepApproval
        derivedSurgeryStatus={derivedSurgeryStatus}
        handleSetAssetStatus={handleSetAssetStatus}
        setShowGenericAlertDialog={setShowGenericAlertDialog}
        setAlertDialogDetails={setAlertDialogDetails}
        setAssetStatusMutation={setAssetStatusMutation}
      />
    )
  }

  const CancelApprovalButton = () => (
    <LoadingButton
      dataTestId="cancel-approval-process-button"
      mode="cancel"
      variant="outlined"
      loading={setAssetStatusSecondaryMutation.loading}
      disabled={procedureStatus !== 'PRE_APPROVAL'}
      loadingText="Cancelling approval process"
      onClick={handleCancelApproval}
    >
      Cancel approval process
    </LoadingButton>
  )

  const addendumModal = () => {
    const maxChar = 100
    return (
      <CustomModal
        open={addendums.show}
        secondaryButtonAction={() =>
          setAddendums((prev) => ({
            ...prev,
            show:
              prev.newAddendum === null || prev.addendumsData.length === 0
                ? false
                : true,
            newAddendum: prev.addendumsData.length > 0 ? null : '',
          }))
        }
        primaryButtonText={
          addendums.newAddendum !== null ? 'Submit' : 'Add New'
        }
        primaryButtonDisabled={
          loadingAddendum ||
          updateSurgeryLoading ||
          (addendums.newAddendum !== null && addendums.newAddendum.length === 0)
        }
        secondaryButtonText={
          addendums.newAddendum !== null || addendums.addendumsData.length === 0
            ? 'Cancel'
            : 'Close'
        }
        secondaryButtonDisabled={loadingAddendum || updateSurgeryLoading}
        header={
          addendums.newAddendum !== null
            ? `Enter Addendum - ${maxChar} characters max`
            : 'Addendums'
        }
        isLoadingState={loadingAddendum}
        handleOnSubmit={handleSubmitAddendum}
      >
        <div className={styles.addendumsWrapper}>
          {addendums.newAddendum === null ? (
            addendums.addendumsData.length > 0 ? (
              addendums.addendumsData?.map((addendum) => (
                <>
                  <TextField
                    disabled
                    rows={5}
                    multiline
                    value={`${addendum.text}\n\n${addendum.createdBy}\n${addendum.createdAt}`}
                    className={styles.addendumTextField}
                  />
                </>
              ))
            ) : (
              <TextField
                rows={5}
                multiline
                value={addendums?.newAddendum}
                className={styles.addendumTextField}
                onChange={(e) => {
                  setAddendums((prev) => {
                    const newText = e.target.value

                    if (prev.newAddendum) {
                      return newText.length <= maxChar ||
                        newText.length < prev.newAddendum.length
                        ? { ...prev, newAddendum: newText }
                        : prev
                    }

                    return { ...prev, newAddendum: newText }
                  })
                }}
              />
            )
          ) : (
            <TextField
              rows={5}
              multiline
              value={addendums?.newAddendum}
              className={styles.addendumTextField}
              onChange={(e) => {
                setAddendums((prev) => {
                  const newText = e.target.value

                  if (prev.newAddendum) {
                    return newText.length <= maxChar ||
                      newText.length < prev.newAddendum.length
                      ? { ...prev, newAddendum: newText }
                      : prev
                  }

                  return { ...prev, newAddendum: newText }
                })
              }}
            />
          )}
        </div>
      </CustomModal>
    )
  }

  const handleSubmitAddendum = () => {
    if (!addendums.newAddendum) {
      setAddendums((prev) => ({ ...prev, newAddendum: '' }))
      return
    }
    addAddendum({
      variables: {
        input: {
          surgeryId: surgery._id,
          addendum: addendums.newAddendum,
        },
      },
    }).then((response) => {
      if (response.data?.addAddendum.success) {
        updateSurgery().then((response) => {
          const addendums: TAddendumsData[] | null =
            response?.data?.getSurgery?.addendums || null

          if (addendums) {
            setAddendums((prev) => ({
              ...prev,
              newAddendum: null,
              addendumsData: addendums
                .map((addendum) => ({
                  ...addendum,
                  createdAt: new Date(
                    addendum.createdAt as string
                  ).toLocaleString(),
                }))
                .reverse(),
            }))
            enqueueSnackbar('Addendum added successfully', {
              variant: 'success',
            })
          } else {
            enqueueSnackbar('Error fetching addendums from database', {
              variant: 'error',
            })
          }
        })
      } else {
        enqueueSnackbar('Failed to add addendum', { variant: 'error' })
      }
    })
  }

  const hasHardware =
    groupedAssetData.hardware.nurseScans.length > 0 ||
    groupedAssetData.hardware.repScans.length > 0 ||
    groupedAssetData.hardware.approved.length > 0

  const hasBiologicConsumableOther =
    totalImplantableBiologicCount > 0 ||
    totalImplantableOtherCount > 0 ||
    totalConsumableCount > 0

  return {
    hasHardware,
    hasBiologicConsumableOther,
    printHeader,
    isNurseAdmin,
    setAddendums,
    isRep,
    setSurgeryStatusMutation,
    setSurgeryStatusSubmittedMutation,
    setAssetStatusMutation,
    setAssetStatusSecondaryMutation,
    demoMenuEvent,
    isGroupedAssetsEmpty,
    groupedAssetData,
    isQrOpen,
    setIsQrOpen,
    showGenericAlertDialog,
    alertDialogDetails,
    showDemoMenu,
    setShowDemoMenu,
    heading,
    isNurseUser,
    surgeryRepCount,
    procedureStatus,
    workflow,
    hasImplantableHardware,
    totalHardwareCount,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
    timeSubmitted,
    enableSendToRep,
    handleSetAssetStatus,
    handleSetProcedureStatus,
    handleCloseModal,
    handleSubmitRecord,
    renderRecordApprovalForm,
    CancelApprovalButton,
    enableImplantRecordSender,
    enableSendProductRegistration,
    enableSendImplantReport,
    renderCloseCase,
    dtmHardwareAssetData,
    repCompanyNames,
    flags,
    loadingRepCompanies,
    loadingAddendum,
    handleSubmitAddendum,
    addendumModal,
    isNurse,
    isMobile,
    hidePrint,
    authorizedReps,
  }
}

import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, Paper } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'

import { useAssignedDigitalTrays } from '../AssignedDigitalTrays.context'
import { ItemResponse, SubItem } from 'common/types'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import './TrayComponent.scss'

interface TrayComponentProps {
  parentTray: ItemResponse
  subTray: SubItem
}

const TrayComponent = ({ parentTray, subTray }: TrayComponentProps) => {
  const navigate = useNavigate()
  const { setSelectedTray, setSelectedSubTray } = useAssignedDigitalTrays()

  const subTrayDetails = extractSubTrayJSONData(subTray)

  const trayData = [
    {
      fieldName: 'Type',
      data: subTrayDetails?.trayType || 'Unknown',
    },
    { fieldName: 'Status', data: 'Loaded from SPD' },
    {
      fieldName: 'Total Product Count',
      data:
        (subTrayDetails?.screws?.length || 0) +
        (subTrayDetails?.plates?.length || 0),
    },
    { fieldName: 'Content', data: 'Screws' },
    { fieldName: 'Vendor', data: subTray.companyName },
  ]

  const handleTrayClick = () => {
    const updatedProductDetails = JSON.stringify({
      ...subTrayDetails,
      screws: subTrayDetails?.screws || [],
      plates: subTrayDetails?.plates || [],
    })

    setSelectedSubTray({
      ...subTray,
      description: subTray.description || '',
      productDetails: updatedProductDetails,
    } as SubItem)
    setSelectedTray(parentTray)
    
    navigate(`tray-details/${subTray.id}`)
  }

  return (
    <Paper 
      className="tray-component" 
      onClick={handleTrayClick}
      elevation={1}
      role="button"
      aria-label={`View details for ${subTray.description}`}
      tabIndex={0}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width="100%">
        <Box sx={{ flex: 1 }}>
          <Stack className="tray-data_container">
            <img src={TrayIcon} alt="" className="tray-icon" />
            <Box className="tray-data">
              <Typography className="tray-data_name">
                {subTray.description}
              </Typography>
              {trayData.map((dataField) => (
                <Typography
                  key={dataField.fieldName}
                  variant="body2"
                  data-field={dataField.fieldName}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    width: '100%',
                  }}
                >
                  {dataField.fieldName}:{' '}
                  <span className="field-value">{dataField.data}</span>
                </Typography>
              ))}
            </Box>
            <ArrowForwardIos className="arrow-icon" />
          </Stack>
        </Box>
      </Stack>
    </Paper>
  )
}

export default TrayComponent

// Components
import { Box, FormControl, TextField, Typography } from '@mui/material'
import { CustomModal } from 'components/molecules/Modal/Modal'

// Logic
import { useBatchDispositionModalLogic } from './BatchDispositionModal.logic'

// Types
import { BatchDispositionModalProps } from './BatchDispositionModal.types'
import {
  ASSET_TYPE_LIST,
  EXPLANTED_REASON_LIST,
  WASTED_REASON_LIST,
  getDispositionList,
} from 'common/disposition'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'
import MediaUpload from 'components/organisms/MediaUpload/MediaUpload'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import Button from 'components/molecules/Button/Button'
import SelectInput from 'components/SelectInput/SelectInput'

export const BatchDispositionModal = ({
  activeTray,
  assetTrays,
  assetType,
  isOpen,
  isSubmitting,
  dispositionStatus,
  onSave,
  onClose,
  setIsTrayScannerOpen,
  registerTrayScanHandler,
  isManualAddition,
  scan,
  onSaveMedia,
  selectedScans,
  isAutoExplant = false,
  onAutoExplantSave,
}: BatchDispositionModalProps) => {
  const {
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSiteLocationEnabled,
    validationErrors,
    autoFocus,
  } = useBatchDispositionModalLogic({
    activeTray,
    assetType,
    onSave,
    registerTrayScanHandler,
    dispositionStatus,
    scan,
    selectedScans,
    isManualAddition,
    isAutoExplant,
    onAutoExplantSave,
  })

  return (
    <CustomModal
      open={isOpen}
      secondaryButtonAction={onClose}
      dataTestId="batch-disposition"
      header="Add Disposition to Selected Products"
      isLoadingState={isSubmitting || false}
      primaryButtonDisabled={false}
      handleOnSubmit={(e) => {
        if (e) handleSubmit(e)
      }}
      primaryButtonText={isSubmitting ? 'Saving' : 'Disposition'}
    >
      {!isAutoExplant && (
        <>
          <FormControl fullWidth sx={{ my: 2 }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Box flexGrow="1">
                <AutocompleteInput
                  id="assetTray"
                  name="assetTray"
                  label="Inventory Location"
                  placeholder="N/A"
                  value={formState.assetTray}
                  options={assetTrays.filter(Boolean)}
                  handleChangeFormData={handleChangeFormData}
                  onTextInputChange={(e) => {
                    handleChangeFormData({
                      target: {
                        name: 'assetTray',
                        value: e.target.value,
                      },
                    })
                  }}
                  variant="outlined"
                  sx={{
                    my: 0,
                    backgroundColor: 'grayscale.lightest',
                  }}
                  autoSelect={false}
                  autoHighlight={false}
                  disabled={isSubmitting}
                  disableClearable
                />
              </Box>
              <Button
                sx={{
                  height: 40,
                  pt: 1.5,
                }}
                variant="outlined"
                color="primary"
                onClick={() =>
                  setIsTrayScannerOpen && setIsTrayScannerOpen(true)
                }
                className="barcode-button"
              >
                <BarcodeIcon active color="var(--primary)" />
              </Button>
            </Box>
          </FormControl>
          <FormControl fullWidth>
            <SelectInput
              id="assetType"
              name="assetType"
              variant="outlined"
              label="Product Type*"
              value={formState.assetType ?? ''}
              handleChangeFormData={handleChangeFormData}
              disabled={isSubmitting}
              sx={{
                width: '100%',
                backgroundColor: 'grayscale.lightest',
                '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon':
                  {
                    color: 'primary.main',
                  },
              }}
              options={ASSET_TYPE_LIST.slice(0, 2)}
            />
          </FormControl>
          {formState.assetType && formState.assetType !== 'consumable' && (
            <FormControl fullWidth>
              <SelectInput
                id="implantStatus"
                name="implantStatus"
                variant="outlined"
                label="Disposition*"
                value={formState.implantStatus ?? ''}
                handleChangeFormData={handleChangeFormData}
                options={getDispositionList({
                  assetType: formState.assetType,
                })
                  .filter((item) =>
                    assetType !== 'explanted' ? item.id !== 'EXPLANTED' : true
                  )
                  .map((disposition) => ({
                    id: disposition.id,
                    name: disposition.name,
                  }))}
                disabled={isSubmitting || assetType === 'explanted'}
                errorMessage={validationErrors.implantStatus}
                sx={{
                  width: '100%',
                  backgroundColor: 'grayscale.lightest',
                  '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon':
                    {
                      color: 'primary.main',
                    },
                }}
                shrink={true}
                autoFocus={autoFocus.implantStatus}
              />
              {!!validationErrors.implantStatus && (
                <Typography
                  variant="caption"
                  sx={{ color: 'error.main', ml: '0.5em' }}
                >
                  {validationErrors.implantStatus}
                </Typography>
              )}
            </FormControl>
          )}
          {(formState.implantStatus === 'IMPLANTED' ||
            formState.implantStatus === 'EXPLANTED' ||
            !formState.implantStatus) && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <AutocompleteInput
                key={
                  formState.implantSite?.toString() +
                  autoFocus.implantSite.toString()
                }
                id="implantSite"
                name="implantSite"
                label={
                  formState.implantStatus === 'IMPLANTED'
                    ? 'Implant Site*'
                    : 'Explant Site*'
                }
                value={formState.implantSite}
                options={implantSiteList}
                handleChangeFormData={handleChangeFormData}
                sx={{
                  my: 0,
                  backgroundColor: 'grayscale.lightest',
                  ...(!!validationErrors?.implantSite && {
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'red',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                      '&:hover fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }),
                }}
                variant="outlined"
                disabled={isSubmitting || !!scan?.originalImplantAssetId}
                errorMessage={validationErrors.implantSite}
                disableClearable
                autoFocus={autoFocus.implantSite}
                autoSelect={false}
              />
            </FormControl>
          )}
          {isSiteLocationEnabled && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                focused={autoFocus.siteLocation}
                autoFocus={autoFocus.siteLocation}
                variant="outlined"
                id="siteLocation"
                name="siteLocation"
                label={
                  formState.implantStatus === 'IMPLANTED'
                    ? 'Implant Location*'
                    : 'Explant Location*'
                }
                value={formState.siteLocation ?? ''}
                placeholder={
                  formState.implantStatus === 'IMPLANTED'
                    ? 'Implant Location*'
                    : 'Explant Location*'
                }
                inputMode="text"
                error={!!validationErrors.siteLocation}
                sx={{ backgroundColor: 'grayscale.lightest' }}
                onChange={handleChangeFormData}
                disabled={isSubmitting || !!scan?.originalSiteLocation}
              />
              <Typography variant="caption" sx={{ color: 'red', ml: '0.5em' }}>
                {validationErrors.siteLocation}
              </Typography>
            </FormControl>
          )}
          {formState.implantStatus === 'WASTED' && (
            <FormControl fullWidth>
              <SelectInput
                id="wastedReason"
                name="wastedReason"
                variant="outlined"
                label="Wasted Reason*"
                value={formState.wastedReason ?? ''}
                handleChangeFormData={handleChangeFormData}
                disabled={isSubmitting}
                autoFocus={autoFocus.wastedReason}
                errorMessage={validationErrors.wastedReason}
                options={WASTED_REASON_LIST}
                sx={{
                  backgroundColor: 'grayscale.lightest',
                  '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon':
                    {
                      color: 'primary.main',
                    },
                }}
              />
              <Typography
                variant="caption"
                sx={{ color: 'error.main', ml: '0.5em' }}
              >
                {validationErrors.wastedReason}
              </Typography>
            </FormControl>
          )}
        </>
      )}
      {formState.implantStatus === 'EXPLANTED' && (
        <FormControl fullWidth>
          <SelectInput
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            label="Explanted Reason*"
            value={formState.explantedReason ?? ''}
            handleChangeFormData={handleChangeFormData}
            disabled={isSubmitting}
            autoFocus={autoFocus.explantedReason}
            options={EXPLANTED_REASON_LIST}
            errorMessage={validationErrors.explantedReason}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{ color: 'error.main', ml: '0.5em' }}
          >
            {validationErrors.explantedReason}
          </Typography>
        </FormControl>
      )}

      {formState.explantedReason === 'OTHER' &&
        formState.implantStatus === 'EXPLANTED' && (
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              id={`explanted-reason-note`}
              label="Other reason*"
              name="explantedReasonNote"
              value={formState.explantedReasonNote ?? ''}
              placeholder="Other Reason*"
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={isSubmitting}
              error={!!validationErrors.explantedReasonNote}
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.explantedReasonNote}
            </Typography>
          </FormControl>
        )}
      <MediaUpload
        disableActions={isSubmitting}
        hasActions
        scanId={scan?._id as string}
        fileNames={[]}
        onMediaFilesChange={(media, action) => onSaveMedia?.(media, action)}
      />
    </CustomModal>
  )
}

import { TransformComponent } from 'react-zoom-pan-pinch'
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  KeyboardBackspace,
  Replay,
} from '@mui/icons-material'
import classNames from 'classnames'

import useTrayProcedureEditModeLogic from './TrayProcedureEditMode.logic'
import getIsMobileOrTablet from 'lib/utils/getIsMobileOrTablet'

import { TrayProcedureEditModeProps } from './TrayProcedureEditMode.types'
import './TrayProcedureEditMode.scss'
import TrayMapSelector from '../TrayMapSelector/TrayMapSelector'

const TrayProcedureEditMode = ({
  mapContent,
  trayType,
  onClose,
  onClickActions,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  handlePlateUsage,
  getPlateCount,
  setSelectedPlate,
  setModalOpen,
  setSelectedScrew,
}: TrayProcedureEditModeProps) => {
  const {
    handlePan,
    zoomIn,
    zoomOut,
    resetTransform,
    selectedScrewsCount,
  } = useTrayProcedureEditModeLogic()

  return (
    <div
      className={classNames('tray-procedure-edit-mode-organism_wrapper', {
        'is-mobile': getIsMobileOrTablet(),
      })}
    >
      <button className="back-button" onClick={onClose}>
        <KeyboardBackspace />
      </button>

      <div className="joystick-container">
        <button onClick={() => handlePan('right')}>
          <ArrowDropUp />
        </button>
        <div>
          <button onClick={() => handlePan('up')}>
            <ArrowLeft />
          </button>
          <button onClick={() => handlePan('down')}>
            <ArrowRight />
          </button>
        </div>
        <button onClick={() => handlePan('left')}>
          <ArrowDropDown />
        </button>
      </div>

      <div className="tray-map_wrapper">
        <TransformComponent
          contentStyle={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          wrapperStyle={{
            width: '100%',
            height: '100%',
          }}
        >
          <TrayMapSelector
            trayType={trayType}
            isInOR
            mapContent={mapContent}
            onClickActions={onClickActions}
            isScrewEnabled={isScrewEnabled}
            isScrewImplanted={isScrewImplanted}
            isScrewWasted={isScrewWasted}
            handlePlateUsage={handlePlateUsage}
            getPlateCount={getPlateCount}
            setSelectedPlate={setSelectedPlate}
            setModalOpen={setModalOpen}
            setSelectedScrew={setSelectedScrew}
          />
        </TransformComponent>
      </div>

      {selectedScrewsCount > 0 && (
        <div className="selection-actions">
          <button 
            className="toggle-button"
            onClick={() => setModalOpen(true)}
          >
            Confirm Selection ({selectedScrewsCount})
          </button>
        </div>
      )}

      <div className="zoom-controls">
        <button onClick={() => zoomIn(0.5)}>+</button>
        <button onClick={() => zoomOut(0.5)}>-</button>
        <button onClick={() => resetTransform()}>
          <Replay />
        </button>
      </div>
    </div>
  )
}

export default TrayProcedureEditMode

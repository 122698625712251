import {
  Typography,
  Tooltip,
  Button,
} from '@mui/material'
import { TransformWrapper } from 'react-zoom-pan-pinch'
import { useState } from 'react'

import { LoadingButton } from 'components/mui'
import RemoveScrewModal from './Modals/RemoveScrewModal/RemoveScrewModal'
import ConfirmChangesModal from './Modals/ConfirmChangesModal/ConfirmChangesModal'
import ReloadScrewModal from './Modals/ReloadScrewModal/ReloadScrewModal'
import HelpModal from './Modals/HelpModal/HelpModal'

import useTrayVisualizationLogic from './TrayVisualization.logic'

import './TrayVisualization.scss'
import classNames from 'classnames'
import FullScreenDialog from 'components/organisms/FullScreenDialog/FullScreenDialog'
import TrayProcedureEditMode from 'components/organisms/TrayProcedureEditMode/TrayProcedureEditMode'
import TrayMapSelector from 'components/organisms/TrayMapSelector/TrayMapSelector'
import {
  ArrowBack,
  PhotoLibrary,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import ViewImageDialog from 'components/organisms/ViewImageDialog/ViewImageDialog'

const TrayVisualization = () => {
  const {
    selectedScrew,
    trayHeight,
    modalOpen,
    selectedStatus,
    setSelectedStatus,
    confirmChangesModalOpen,
    setConfirmChangesModalOpen,
    isConfirmScrewRemovalModalOpen,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isScrewUsed,
    isScrewUsedAndWasted,
    isScrewLoadedFromSPD,
    handleStatusChange,
    handleConfirm,
    handleSaveChangesCancellation,
    handleReloadScrew,
    handleReloadScrewModalClose,
    handleHelpModalClose,
    handleConfirmChangesModalClose,
    handleRemoveScrewModalClose,
    isInEditMode,
    setIsInEditMode,
    trayType,
    setSelectedScrew,
    getInitialPlateCount,
    handlePlateUsage,
    mapContent,
    onClickActions,
    selectedPlate,
    handlePlateStatusChange,
    setSelectedPlate,
    setModalOpen,
    trayDetails,
    selectedScrews,
  } = useTrayVisualizationLogic()

  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false)

  if (!trayDetails) {
    return <div>Loading tray details...</div>
  }

  return (
    <div
      className={classNames('dtm-tray-visualization_container', {
        'with-plates': trayType !== 'stryker screw caddy',
      })}
      style={{ height: trayHeight }}
    >
      <div className="header-buttons">
        <Button
          className="back-button"
          startIcon={<ArrowBack />}
          size="small"
          component={Link}
          to=".."
        >
          Back
        </Button>

        <div className="guide-and-reference-image-buttons">
          <button
            className="help-button"
            onClick={() => setIsHelpModalOpen(true)}
          >
            ?
          </button>

          <Tooltip title="View Tray Reference Image" placement="left">
            <Button
              className="tray-reference-image-button"
              color="primary"
              variant="contained"
              startIcon={<PhotoLibrary />}
              onClick={() => setIsImagePreviewOpen(true)}
              aria-label="View tray reference image"
            >
              Reference Image
            </Button>
          </Tooltip>
        </div>
      </div>

      <Typography variant="h2">Confirm Utilized Hardware</Typography>

      <div className="tray-map_wrapper">
        {mapContent && (
          <TrayMapSelector
            trayType={trayType}
            mapContent={mapContent}
            isInOR={true}
            isScrewEnabled={isScrewLoadedFromSPD}
            isScrewImplanted={isScrewUsed}
            isScrewWasted={isScrewUsedAndWasted}
            hideButton={true}
          />
        )}
      </div>

      <div className="buttons-container">
        <div className="left-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            onClick={() => setIsInEditMode(true)}
            className="view-modify-tray_button"
          >
            Select Hardware Used In Case
          </LoadingButton>
        </div>

        <div className="right-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="contained"
            onClick={() => setConfirmChangesModalOpen(true)}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>

      <FullScreenDialog
        open={isInEditMode}
        onClose={() => setIsInEditMode(false)}
      >
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          pinch={{
            step: 10,
          }}
          wheel={{
            step: 10,
          }}
          smooth
          doubleClick={{
            disabled: true,
          }}
        >
          <TrayProcedureEditMode
            trayType={trayType}
            mapContent={mapContent}
            onClose={() => setIsInEditMode(false)}
            onClickActions={onClickActions}
            isScrewEnabled={isScrewLoadedFromSPD}
            isScrewImplanted={isScrewUsed}
            isScrewWasted={isScrewUsedAndWasted}
            handlePlateUsage={handlePlateUsage}
            getPlateCount={getInitialPlateCount}
            setSelectedPlate={setSelectedPlate}
            setModalOpen={setModalOpen}
            setSelectedScrew={setSelectedScrew}
          />
        </TransformWrapper>
      </FullScreenDialog>

      <RemoveScrewModal
        trayType={trayType}
        open={modalOpen}
        onClose={handleRemoveScrewModalClose}
        selectedScrews={selectedScrews}
        selectedPlate={selectedPlate}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleStatusChange={
          selectedPlate ? handlePlateStatusChange : handleStatusChange
        }
      />
      <ConfirmChangesModal
        open={confirmChangesModalOpen}
        onClose={handleConfirmChangesModalClose}
        handleConfirm={handleConfirm}
        handleSaveChangesCancellation={handleSaveChangesCancellation}
      />
      <ReloadScrewModal
        open={isConfirmScrewRemovalModalOpen}
        onClose={handleReloadScrewModalClose}
        selectedScrew={selectedScrew}
        handleReloadScrew={handleReloadScrew}
      />
      <HelpModal open={isHelpModalOpen} onClose={handleHelpModalClose} />

      <ViewImageDialog
        open={isImagePreviewOpen}
        handleClose={() => setIsImagePreviewOpen(false)}
        trayImage={trayDetails?.trayImg}
      />
    </div>
  )
}

export default TrayVisualization

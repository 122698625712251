import { useEffect } from 'react'
import { useControls, useTransformContext } from 'react-zoom-pan-pinch'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useTrayProcedureEditModeLogic = () => {
  const { zoomIn, zoomOut, setTransform, resetTransform } = useControls()
  const transformContext = useTransformContext()

  const { selectedScrewIndices } = useSPD()
  const selectedScrewsCount = selectedScrewIndices.length

  useEffect(() => {
    resetTransform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePan = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (transformContext.mounted) {
      const panStep = 130
      const {
        positionX,
        positionY,
        previousScale: scale,
      } = transformContext.getContext().state

      switch (direction) {
        case 'up':
          setTransform(positionX, positionY - panStep, scale)
          break
        case 'down':
          setTransform(positionX, positionY + panStep, scale)
          break
        case 'left':
          setTransform(positionX - panStep, positionY, scale)
          break
        case 'right':
          setTransform(positionX + panStep, positionY, scale)
          break
      }
    }
  }

  return {
    handlePan,
    zoomIn,
    zoomOut,
    resetTransform,
    selectedScrewsCount,
  }
}

export default useTrayProcedureEditModeLogic
